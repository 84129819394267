import React from "react"
import { Control } from "react-hook-form"
import UpgradeAlert from "components/elements/UpgradeAlert"
import BasicInputs from "./BasicInputs"
import FormDetailHeader from "./FormDetailHeader"

type Props = {
  control: Control<object, any>
  showUpgradeAlert: boolean
  name: string
}

const RSVP = ({ control, showUpgradeAlert, name }: Props) => (
  <>
    <FormDetailHeader
      name={name}
      description="Distributed via Diobox Emails, this form generates a unique link for each recipient to ensure that the RSVP is private and non-transferrable by the guest."
    />
    {showUpgradeAlert && <UpgradeAlert noBorder mb={8} text="Requires Professional Subscription" />}
    <BasicInputs control={control} showQuantityInput />
  </>
)

export default RSVP
