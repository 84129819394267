import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { DioboxEvent } from "sharedTypes"
import _ from "lodash"
import pluralize from "pluralize"

type Props = {
  recipients: string[]
  guestCount: number
  event: DioboxEvent
}

const SelectedRecipients = ({
  recipients,
  guestCount,
  event: {
    segments,
    eventGroups,
    tables: eventTables,
  },
}: Props) => {
  const defaultSegments = [
    { id: "group_all", label: "All Guests", icon: { icon: "guests", size: 6, color: "blue.400" } },
    { id: "group_confirmed", label: "Confirmed", icon: { icon: "dot", color: "blue.300" } },
    { id: "group_unconfirmed", label: "Unconfirmed", icon: { icon: "dot", color: "gray.300" } },
    { id: "group_waitlisted", label: "Waitlisted", icon: { icon: "dot", color: "yellow.300" } },
    { id: "group_declined", label: "Declined", icon: { icon: "dot", color: "red.300" } },
    { id: "group_checked_in", label: "Checked In", icon: { icon: "dot", color: "green.300" } },
    { id: "group_unarrived", label: "Unarrived", icon: { icon: "dot", color: "orange.300" } },
  ]
  const customSegments = _.sortBy(segments, "icon").map(({ id, name, color }) => ({ id: `segment_${id}`, label: name, icon: { icon: "dot", color: `${color}.500` } }))
  const groups = _.sortBy(eventGroups, "icon").map(({ id, title, icon }) => ({ id: `group_${id}`, label: title, icon: { icon: icon ? `event-group-${icon}` : "tag", color: "gray.600" } }))
  const tables = _.sortBy(eventTables, (table) => table.name.toLowerCase()).map(({ id, name }) => ({ id: `table_${id}`, label: name, icon: { icon: "tables-stroke", color: "gray.600" } }))

  const allSegments = [...defaultSegments, ...customSegments, ...groups, ...tables]
  const filteredSegments = allSegments.filter((segment) => recipients.includes(segment.id))

  return (
    <Box>
      <Flex alignItems="center" gap={2} mb={2}>
        {recipients.includes("group_all") ? <><Icon icon="guests" size={6} color="blue.400" /><Text>All Guest</Text></> : (
          <Box gap={3}>
            {filteredSegments.map(
              ({
                label, icon: { icon, color },
              }) => <Flex alignItems="center" gap={2}><Icon icon={icon} color={color} /><Text>{label}</Text></Flex>,
            )}
          </Box>
        )}
      </Flex>
      <Text fontSize="sm">{guestCount} {pluralize("recipient", guestCount)} with valid phone {pluralize("number", guestCount)}</Text>
    </Box>
  )
}

export default SelectedRecipients
