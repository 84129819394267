import React from "react"
import Select from "components/ReactHookForm/Select"
import TextField from "components/ReactHookForm/TextField"
import TextArea from "components/ReactHookForm/TextArea"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import { Control } from "react-hook-form"
import { Stack } from "@chakra-ui/react"
import { Boolean, CURRENCY_OPTIONS } from "utilities/enums"
import RichTextField, { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import DropdownSelect from "./DropdownSelect"
import PriceWithRadio from "./Ticket/PriceWithRadio"

type Props = {
  control: Control<object, any>;
  showVisibilityInput?: boolean
  showQuantityInput?: boolean
  showLimitPerSubmission? : boolean
  showPrice?: boolean
  currency?: string
}

const BasicInputs = ({
  control, showVisibilityInput, showQuantityInput, showLimitPerSubmission, showPrice, currency,
}: Props) => {
  const currencyName = CURRENCY_OPTIONS.find(
    (currencyOption) => currencyOption.value === currency,
  )?.label

  return (
    <Stack spacing={6}>
      <TextField
        name="name"
        control={control}
        label="Name"
        variant="outline"
      />
      <TextArea
        name="description"
        control={control}
        label="Description"
        helperText="The form name and description will appear on the Guest Portal and receipt."
        placeholder="Description (optional)"
      />
      {showVisibilityInput && (
        <Select
          name="private"
          control={control}
          label="Visibility"
          options={visibilityOptions}
          helperText="Make this “Private” to hide it from the Guest Portal listing."
          fullWidth
        />
      )}
      <DatePickerField
        showTimeSelect
        name="submissionsEndAt"
        control={control}
        label="Closing Time"
        variant="outline"
        helperText="The form will close on this date.
              You can clear this date if you don't want the form to close, even after your event ends."
        showDeleteButton
      />
      {showQuantityInput && (
      <TextField
        name="quantity"
        control={control}
        label="Quantity"
        variant="outline"
        helperText="The form will close after the quantity is reached."
        type="number"
        showDeleteButton
      />
      )}
      {showLimitPerSubmission && (
        <DropdownSelect
          name="limitPerSubmission"
          control={control}
          label={`Max per ${showPrice ? "Order" : "Submission"}`}
        />
      )}
      {showPrice && (
        <PriceWithRadio
          control={control}
          enabledLabel="Regular Pricing"
          disabledLabel="Variable / Donation"
          w={40}
          rightText={currencyName}
        />
      )}
      <RichTextField
        label="Submission Message (optional)"
        name="submissionMessage"
        helperText="Provide a message that will be displayed on the confirmation page once the form has been successfully submitted."
        control={control}
        type={RichTextFieldType.FORM_BLOCK_TEXT}
      />
    </Stack>
  )
}

const visibilityOptions = [{ label: "Public", value: Boolean.False }, { label: "Private", value: Boolean.True }]

export default BasicInputs
