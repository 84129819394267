import React from "react"
import _ from "lodash"
import Icon from "components/elements/Icon"
import pluralize from "pluralize"
import * as api from "api/guests"
import { successToast } from "utilities/toasts"
import { refetchGuests } from "context/actions"
import { Box, Text } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { useShallow } from "zustand/shallow"
import useGuestsStore from "services/useGuestsStore"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import Select from "components/ReactHookForm/Select"

type Props = {
  onClose: () => void;
  selectedCount: number;
}

enum Action {
  Add = "add",
  Remove = "remove",
}

const TagGuestList = ({ onClose, selectedCount }: Props) => {
  const { data: currentEvent } = useCurrentEvent()
  const queryClient = useQueryClient()
  const {
    selectedGuestIds,
    deselectAll,
    allSelected,
    groupId,
    segmentId,
  } = useGuestsStore(
    useShallow((state) => ({
      selectedGuestIds: state.selectedGuestIds,
      deselectAll: state.deselectAll,
      allSelected: state.allSelected,
      groupId: state.groupId,
      segmentId: state.segmentId,
    })),
  )

  const allGroups = _.sortBy((currentEvent.eventGroups || []), ["icon", "title"])
  const initialValues = {
    action: Action.Add,
    eventGroups: [],
  }

  const onSubmit = (values) => {
    const { action, eventGroups } = values

    return api[action === Action.Add ? "postGuestsToGuestLists" : "deleteGuestsFromGuestLists"](
      currentEvent.id,
      eventGroups,
      selectedGuestIds,
      allSelected ? segmentId : undefined,
      allSelected ? groupId : undefined,
    ).then(() => {
      const guestListsCount = eventGroups.length
      successToast(
        { title: `Successfully ${action === Action.Add ? "added" : "removed"} ${guestListsCount} ${pluralize("guest list", guestListsCount)} ${action === Action.Add ? "to" : "from"} ${selectedCount} ${pluralize("guest", selectedCount)}` },
      )
      refetchGuests(queryClient, currentEvent.id)
      deselectAll()
    }).catch(() => {
      // API service automatically alerts the user, show must go on without closing the modal
    })
  }

  return (
    <ModalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Tag Guest List"
      submitText="Apply"
    >
      {({ control, watch }) => {
        const action = watch("action")

        return (
          <>
            <Text fontSize="xl" mb={6}>
              <Box as="span" display="inline-block" mr={2}>
                <Select
                  name="action"
                  control={control}
                  options={[{ label: "Add", value: Action.Add }, {
                    label: "Remove",
                    value: Action.Remove,
                  }]}
                />
              </Box> the following guest lists {action === Action.Add ? "to " : "from "}
              <Text
                as="strong"
                display="inline"
                fontWeight="semibold"
              >
                {selectedCount}
              </Text> {pluralize("guest", selectedCount)}:
            </Text>
            <Checkboxes
              name="eventGroups"
              control={control}
              items={allGroups}
              itemComponent={GuestListItem}
            />
          </>
        )
      }}
    </ModalForm>
  )
}

const GuestListItem = ({ icon, title }) => (
  <>
    <Icon
      size={5}
      icon={icon ? `event-group-${icon}` : "tag"}
      mx={1}
      mt={-1}
      color="gray.500"
    /> {title}
  </>
)

export default TagGuestList
