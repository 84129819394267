import { PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

const validatePhoneNumber = (phoneNumber: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, !phoneNumber.startsWith("+") ? "US" : undefined)

    return phoneUtil.isValidNumber(number)
  } catch (e) {
    return false
  }
}

export default validatePhoneNumber
