import React from "react"
import * as api from "api/guests"
import pluralize from "pluralize"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { Text } from "@chakra-ui/react"
import { infoToast } from "utilities/toasts"
import { removeSelectedGuests } from "context/actions"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import useGuestsStore from "services/useGuestsStore"
import { useShallow } from "zustand/shallow"
import { selectedFilterCount } from "../../../ScrollView/Filters"

type Props = {
  onClose: () => void;
}

const RemoveFromEvent = ({ onClose }: Props) => {
  const queryClient = useQueryClient()
  const {
    data: {
      id: eventId,
      eventGroups,
      counts,
      segments,
    },
  } = useCurrentEvent()
  const {
    selectedGuestIds,
    deselectAll,
    allSelected,
    groupId,
    segmentId,
  } = useGuestsStore(
    useShallow((state) => ({
      selectedGuestIds: state.selectedGuestIds,
      deselectAll: state.deselectAll,
      allSelected: state.allSelected,
      groupId: state.groupId,
      segmentId: state.segmentId,
    })),
  )

  let selectedGuestsCount
  let deleteGuests

  if (allSelected) {
    selectedGuestsCount = selectedFilterCount(eventGroups, counts, segments, segmentId, groupId)

    deleteGuests = async () => {
      try {
        await api.deleteGuests(
          eventId,
          undefined,
          segmentId,
          groupId,
        )
      } catch {
        // API service automatically alerts the user, show must go on without closing the modal
      }
      deselectAll()
    }
  } else {
    selectedGuestsCount = selectedGuestIds.length

    deleteGuests = async () => {
      const guestIds = selectedGuestIds

      try {
        await api.deleteGuests(
          eventId,
          guestIds,
        )
      } catch {
        // API service automatically alerts the user, show must go on without closing the modal
      }
      removeSelectedGuests(queryClient, eventId)
      deselectAll()
    }
  }

  const initialValues = {}

  const onSubmit = async () => {
    await deleteGuests()
    infoToast({ title: "Deleting in progress. The time to process depends on the number of selected guests." })
  }

  return (
    <ModalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Remove Guests"
      submitColorScheme="button.danger"
      submitText={`Remove ${selectedGuestsCount} ${pluralize("Guest", selectedGuestsCount)}`}
    >
      <Text fontSize="xl">Are you sure you want to remove {selectedGuestsCount} {pluralize("guest", selectedGuestsCount)} from this event?</Text>
    </ModalForm>
  )
}

export default RemoveFromEvent
