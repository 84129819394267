/* eslint-disable no-param-reassign */
import { CurrentModal, ModalName } from "sharedTypes"
import { createWithEqualityFn as create } from "zustand/traditional"
import { immer } from "./middlewares"

type Store = {
  modal: CurrentModal | null,
  setModal: (name: ModalName|null, params?: {}) => void;
}

const initialState = {
  modal: null,
}

export const useModalStore = create<Store>(immer((set) => ({
  ...initialState,
  setModal: (
    name: ModalName|null,
    params?: {},
  ) => set((state) => { state.modal = (name === null ? null : { name, params }) as CurrentModal }),
})))

export default () => useModalStore((s) => s.setModal)
