/* eslint-disable no-param-reassign */
import { createWithEqualityFn as create } from "zustand/traditional"
import { produce } from "immer"

type Store = {
  searchString: string,
  groupId: string,
  segmentId?: string,
  order: string,
  allSelected: boolean,
  selectedGuestIds: string[],
  selectAll: () => void;
  deselectAll: () => void;
  addSelectedGuestId:(guestId: string) => void,
  removeSelectedGuestId: (guestId: string) => void,
  filter: (
    { groupId, segmentId }: {groupId?: string, segmentId?: string, searchString?: string}
  ) => void;
  sort: (order) => void;
  reset: () => void;
}

const immer = (config) => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === "function"
    ? produce(partial)
    : partial

  return set(nextState, replace)
}, get, api)

const initialState = {
  searchString: "",
  groupId: "",
  segmentId: undefined,
  order: "first_name_asc",
  allSelected: false,
  selectedGuestIds: [],
}

export default create<Store>(immer((set) => ({
  ...initialState,
  addSelectedGuestId: (guestId) => set(({ selectedGuestIds: ids }) => {
    ids.push(guestId)
  }),
  removeSelectedGuestId: (guestId) => set(({ selectedGuestIds: ids }) => {
    const index = ids.indexOf(guestId)
    if (index !== -1) ids.splice(index, 1)
  }),
  selectAll: () => set((s) => {
    s.searchString = ""
    s.selectedGuestIds.splice(0, s.selectedGuestIds.length)
    s.allSelected = true
  }),
  deselectAll: () => set((s) => {
    s.selectedGuestIds.splice(0, s.selectedGuestIds.length)
    s.allSelected = false
  }),
  filter: ({ groupId, segmentId, searchString = "" }) => set((s) => {
    s.searchString = searchString
    s.groupId = groupId
    s.segmentId = segmentId
  }),
  sort: (order) => set((s) => {
    s.order = order
  }),
  reset: () => set((state) => ({ ...state, ...initialState })),
})))
