import { getTextMessage, getTextMessages } from "api/texts"
import { useInfiniteQuery, useQuery } from "react-query"
import { TextMessage } from "sharedTypes"

export const paginatedTextMessagesCacheKey = (eventId: string) => ["texts", eventId]
export const textMessagesCacheKey = (eventId: string) => ["texts", "non-paginated", eventId]
export const textMessageCacheKey = (eventId: string) => ["text", eventId]

export const usePaginatedTextMessages = (
  eventId,
) => {
  const {
    data, fetchNextPage, isFetching, isLoading,
  } = useInfiniteQuery(
    paginatedTextMessagesCacheKey(eventId),
    ({ pageParam = 1 }) => getTextMessages(eventId, pageParam),
    {
      getNextPageParam: (
        lastPage,
      ) => (lastPage.data.meta.nextPage ?? undefined),
    },
  )

  const texts: TextMessage[] = data?.pages.reduce(
    (prev, curr) => ([...prev, ...curr.data.data]),
    [] as TextMessage[],
  ) || []

  return {
    texts, fetchNextPage, isFetching, isLoading,
  }
}

export const useTextMessages = (eventId: string) => useQuery(
  textMessagesCacheKey(eventId),
  () => getTextMessages(eventId, 1, 100).then(({ data }) => data),
)

export const useTextMessage = (
  eventId: string, textMessageId?: string,
) => useQuery(
  textMessageId ? textMessageCacheKey(textMessageId) : [],
  () => getTextMessage(eventId, textMessageId ?? "").then(({ data }) => data),
  { enabled: Boolean(textMessageId) },
)
