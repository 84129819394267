import {
  Box, HStack, Text, VStack,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import Icon from "components/elements/Icon"
import React from "react"
import ChangeBillingInterval from "pages/Account/Plan/ChangeBillingInterval"
import { ModalName, Plan, Subscription } from "sharedTypes"
import { useIntercom } from "react-use-intercom"
import useModal from "services/useModal"

interface Props {
  subscription: Subscription | null
  currentPlan: Plan
}

const UpgradeEnticer = ({ subscription, currentPlan }: Props) => {
  const showModal = useModal()
  const { show: showIntercom } = useIntercom()
  const { type: planType, upgradeText, upgradeFeatures } = currentPlan

  const feature = ({ icon, text }) => (
    <VStack textAlign="center" w={24} spacing={4} key={icon}>
      <Icon icon={icon} size={12} />
      <Text fontSize="sm" whiteSpace="pre-wrap" lineHeight="normal">{text}</Text>
    </VStack>
  )

  return (
    <>
      <Box bgColor="gray.50" borderWidth={1} p={5}>
        <VStack spacing={6}>
          <Text fontSize="lg">{upgradeText}</Text>
          {planType === "Enterprise" && (
          <Text>
            Contact us to create a custom solution that suits your event management needs.
          </Text>
          )}
          <HStack spacing={8} align="flex-start">
            {upgradeFeatures.map((f) => feature(f))}
          </HStack>
          {planType !== "Enterprise" && <Button onClick={() => showModal(ModalName.Upgrade)}>Upgrade Plan</Button>}
          {planType === "Enterprise" && <Button onClick={showIntercom}>Contact Us</Button>}
        </VStack>
      </Box>
      {subscription?.interval === "month" && !subscription?.legacy && (
        <ChangeBillingInterval currentPlan={currentPlan} toInterval="year" />
      )}
    </>
  )
}

export default UpgradeEnticer
