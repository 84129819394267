import React from "react"
import Spinner from "components/elements/Spinner"
import { useParams } from "react-router-dom"
import {
  EventCounts, ModalName, User, AccountUser, Email,
} from "sharedTypes"
import { eventEmailPath } from "utilities/routes"
import NoContent from "components/elements/NoContent"
import EventResourcesView from "components/layout/EventResourcesView"
import { Box } from "@chakra-ui/react"
import { asDate, asNumber, asPercent } from "utilities/strings"
import { useIntercom } from "react-use-intercom"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import HorizontalStat from "components/elements/HorizontalStat"
import Button from "components/Buttons/Button"
import pluralize from "pluralize"
import SelectPrompt from "components/SelectPrompt"
import { useEmails } from "queries/emails"
import useModal from "services/useModal"
import AlertBox, { AlertBoxType } from "components/elements/AlertBox/AlertBox"
import AlertBoxWrapper from "components/elements/AlertBox/AlertBoxWrapper"
import { emailIcon } from "utilities/forms"
import EventResourcesViewListItem from "components/EventResourcesViewListItem"
import SelectedEmail from "./SelectedEmail"

interface Props {
  eventId: string
  counts: EventCounts
  enabled: boolean
  updateEvent: (params: {}) => Promise<void>
  currentUser: User
  accountUsers: AccountUser[]
}

const Emails = ({
  eventId, counts, enabled, updateEvent, currentUser, accountUsers,
}: Props) => {
  const showModal = useModal()
  const { emailId } = useParams<{ emailId: string, action: string }>()
  const { show: showIntercom } = useIntercom()
  const { isLoading, data: emails = [] } = useEmails(eventId)

  if (emails.length === 0) {
    if (isLoading) {
      return <Spinner />
    }

    return (
      <NoEmails
        enabled={enabled}
        onClick={() => showModal(ModalName.NewEmail)}
        contactUs={showIntercom}
      />
    )
  }

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="emails"
          title="Emails"
          items={(
            <>
              <HorizontalStat label={`${pluralize("Email", counts.emails)} Sent`} value={asNumber(counts.emails)} />
              <HorizontalStat label="Open Rate" value={asPercent(counts.openedEmails / counts.emails)} />
              <HorizontalStat label="Click Rate" value={asPercent(counts.clickedEmails / counts.emails)} />
              <Button leftIcon="plus" onClick={() => showModal(ModalName.NewEmail)} size="sm">
                Email
              </Button>
            </>
          )}
        />
      )}
      body={(
        <EventResourcesView
          listHeader={
            !enabled ? (
              <Box p={4}>
                <PendingEmailVerificationAlert contactUs={showIntercom} />
              </Box>
            ) : null
          }
          listItems={emails.map((email: Email) => (
            <EventResourcesViewListItem
              id={email.id.toString()}
              selectedListItemId={emailId}
              path={eventEmailPath(eventId, email.id)}
              icon={emailIcon(email)}
              title={email.title}
              subtitle={asDate(email.updatedAt)}
              sentOn={email.sentOn}
              sendScheduledAt={email.sendScheduledAt}
            />
          ))}
          showBorder={false}
          selectedItem={emailId ? <SelectedEmail updateEvent={updateEvent} currentUser={currentUser} accountUsers={accountUsers} /> : <SelectPrompt text="Select an Email" />}
          bgColor="gray.50"
        />
      )}
    />
  )
}

const PendingEmailVerificationAlert = ({ contactUs }) => (
  <AlertBox
    type={AlertBoxType.Verification}
    text="Email sending is disabled by default for all newly created accounts to prevent spammers from abusing Diobox. You can still build your campaigns and send test emails to yourself."
    header="Your account is pending verification for email sending"
    buttonText="Contact Us to Enable Email Sending"
    onClick={contactUs}
  />
)

const NoEmails = ({ enabled, onClick, contactUs }) => {
  const descriptionItems = [
    <>
      Design and send event-ready email invitations that<br />
      are linked to RSVP forms and questionnaires.
    </>,
    <>
      Gain insights into email opens, clicks, and guest<br />
      responses through detailed reports.
    </>,
  ]

  return (
    <>
      {!enabled && (
        <AlertBoxWrapper absolute>
          <PendingEmailVerificationAlert contactUs={contactUs} />
        </AlertBoxWrapper>
      )}
      <NoContent
        title="Email & Invitations"
        icon="email-with-form-2"
        descriptionItems={descriptionItems}
        callsToActions={[
          {
            icon: "plus-big", text: "Build First Email", action: onClick, isButton: true,
          }, {
            icon: "book", text: "Learn more about Diobox Emails", link: "https://help.d.io/en/collections/1883583-emails-invitations",
          },
        ]}
      />
    </>
  )
}

export default Emails
