export const MAX_PLUS_ONES_COUNT = 9
export const DATE_FORMAT_OPTIONS = [
  { label: "MM/DD/YYYY (11/05/2022)", value: "MM/DD/YYYY" },
  { label: "DD/MM/YYYY (05/11/2022)", value: "DD/MM/YYYY" },
  { label: "YYYY-MM-DD (2022-11-05)", value: "YYYY-MM-DD" },
]

export const MAX_FORMS_PER_EVENT = 25
export const EMAIL_VALIDATION_REGEX = /^(?:(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|)$/i
export const EMAIL_USER_VALIDATION_REGEX = /^[a-zA-Z0-9._%+-]+$/

export const CHART_COLORS = ["#5DA5DA", "#FAA43A", "#60BD68", "#F17CB0", "#B2912F", "#B276B2", "#DECF3F", "#F15854"]

export enum DateFormat {
  MONTH_DAY_YEAR = "MMMM d, yyyy",
  DAY_OF_WEEK_MONTH_DAY = "EEEE, MMMM do",
  MONTH_DAY_YEAR_AT_HOUR_MINUTE = "M/d/yyyy 'at' h:mm aaa"
}

export const SENDER_PHONE_NUMBER = "+1 646-921-4232"
