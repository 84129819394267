import React from "react"
import {
  Flex, Text, Button, Box,
} from "@chakra-ui/react"
import { asNumber } from "utilities/strings"
import Icon from "./elements/Icon"

type Props = {
  currentPage: number
  pageCount: number
  onChangePage: (currentPage: number) => void
  totalCount: number
  currentPageLength: number
  itemsPerPage?: number
  showPageInfo?: boolean
  showItemInfo?: boolean
}

const PaginationButtons = ({
  currentPage,
  pageCount,
  onChangePage,
  totalCount,
  currentPageLength,
  itemsPerPage = 50,
  showPageInfo,
  showItemInfo,
}: Props) => {
  const from = itemsPerPage * (currentPage - 1) + 1
  const to = from + currentPageLength - 1

  return (
    <Flex alignItems="center">
      {showItemInfo && (
        <Box mr={2}>
          <Text fontWeight="semibold">{totalCount === 0 ? 0 : `${from} - ${to} of ${asNumber(totalCount)}`}</Text>
        </Box>
      )}
      <Button
        variant="ghost"
        size="sm"
        mr={2}
        onClick={() => onChangePage(currentPage > 0 ? (currentPage - 1) : currentPage)}
        isDisabled={currentPage === 1}
      >
        <Icon icon="prev-arrow" />
      </Button>
      {showPageInfo && <Text>Page {currentPage} of {pageCount}</Text>}
      <Button
        variant="ghost"
        size="sm"
        ml={2}
        pr={0}
        onClick={() => onChangePage(currentPage < pageCount ? (currentPage + 1) : currentPage)}
        isDisabled={currentPage === pageCount}
      >
        <Icon icon="next-arrow" />
      </Button>
    </Flex>
  )
}

export default PaginationButtons
