import React from "react"
import {
  Route, Switch, useHistory, useParams, useRouteMatch,
} from "react-router-dom"
import Spinner from "components/elements/Spinner"
import usePermissions from "services/permissions"
import ProtectedRoute from "components/ProtectedRoute"
import { useGuest } from "queries/guests"
import * as api from "api/guests"
import { updateGuest as updateGuestAction } from "context/actions"
import { useQueryClient } from "react-query"
import { Table, AccountUser, DioboxEvent } from "sharedTypes"
import { useEventGuestPaths } from "utilities/routes"
import Show from "./Show"
import Edit from "./Edit"
import ActivityModal from "./Show/ActivityModal"
import SendEmail from "../MultipleSelectActions/Action/SendEmail/SendEmail"
import HistoryAndScoreboard from "./Show/HistoryAndScoreboard"
import CheckInAlert from "./Show/CheckInAlert"
import SendSmartReminder from "../MultipleSelectActions/Action/SendSmartReminder"
import SendTextMessage from "../MultipleSelectActions/Action/SendTextMessage/SendTextMessage"

interface Props {
  currency: string
  enhanceEnabled: boolean
  groupsEnabled: boolean
  tables: Table[]
  timezone: string
  teamMembers: AccountUser[]
  event: DioboxEvent
}

const SelectedGuest = ({
  currency,
  enhanceEnabled,
  groupsEnabled,
  tables,
  timezone,
  teamMembers,
  event,
}: Props) => {
  const {
    eventId, guestId, action,
  } = useParams<{ eventId: string, guestId: string, action: string }>()
  const { canEditGuests, canViewEmails, canViewTexting } = usePermissions()
  const { eventGuestPath } = useEventGuestPaths()

  const { path } = useRouteMatch()
  const history = useHistory()
  const currentGuest = useGuest(eventId, guestId)
  const queryClient = useQueryClient()

  const onClose = () => {
    history.push(eventGuestPath(eventId, guestId))
  }

  if (!currentGuest.id) {
    return <Spinner heightFull />
  }

  const updateGuest = (params) => api.putGuest(
    eventId, guestId, params,
  ).then((response) => {
    updateGuestAction(queryClient, response.data)
  })

  return (
    <Switch>
      <ProtectedRoute
        path={`${path}/(edit|enrich)`}
        exact
        authorized={canEditGuests}
        redirectTo={eventGuestPath(eventId, guestId)}
      >
        <Edit
          eventId={eventId}
          guest={currentGuest}
          updateGuest={updateGuest}
          enrichEnabled={enhanceEnabled}
          enrich={action === "enrich"}
        />
      </ProtectedRoute>
      <Route>
        <Show
          currency={currency}
          guest={currentGuest}
          tables={tables}
          timezone={timezone}
          updateGuest={updateGuest}
          groupsEnabled={groupsEnabled}
          event={event}
        />
        <ProtectedRoute
          path={[
            `${path}/activities/add/:activityType`,
            `${path}/activities/:activityId/edit`,
          ]}
          authorized={canEditGuests}
          redirectTo={eventGuestPath(eventId, guestId)}
        >
          <ActivityModal guest={currentGuest} />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/send-email`}
          authorized={canViewEmails}
          redirectTo={eventGuestPath(eventId, guestId)}
        >
          <SendEmail
            onClose={onClose}
            guest={currentGuest}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/send-smart-reminder`}
          authorized={canViewEmails}
          redirectTo={eventGuestPath(eventId, guestId)}
        >
          <SendSmartReminder
            onClose={onClose}
            guest={currentGuest}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/send-text-message`}
          authorized={canViewTexting}
          redirectTo={eventGuestPath(eventId, guestId)}
        >
          <SendTextMessage
            onClose={onClose}
            guest={currentGuest}
            event={event}
          />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/check-in-alert`}
          authorized={canEditGuests}
          redirectTo={eventGuestPath(eventId, guestId)}
        >
          <CheckInAlert guest={currentGuest} teamMembers={teamMembers} />
        </ProtectedRoute>
        <Route path={`${path}/history-and-scoreboard`}>
          <HistoryAndScoreboard guest={currentGuest} event={event} />
        </Route>
      </Route>
    </Switch>
  )
}

export default SelectedGuest
