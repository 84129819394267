import React from "react"
import {
  Box, Flex, List, ListItem, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { asNumber } from "utilities/strings"
import StatusBar from "components/elements/StatusBar"

export const CheckList = (props) => <List pt={8} pb={2} spacing={3} fontSize="md" {...props} />

type CheckListItemProps = {
  text: string
  checked?: boolean
}

export const CheckListItem = ({ text, checked }: CheckListItemProps) => (
  <ListItem>
    <Flex justify="space-between" fontWeight="semibold">
      {text}
      {checked ? <Icon icon="checkin-filled" color="blue.500" /> : <Icon icon="cross" color="red.500" />}
    </Flex>
  </ListItem>
)

type CheckListStatusProps = {
  text: string
  status: number
  total: number
  showStatusBar?: boolean
}

export const CheckListStatus = ({
  text, status, total, showStatusBar = true,
}: CheckListStatusProps) => {
  const isZeroTotal = status === 0 && total === 0
  const remaining = total - status

  return (
    <ListItem>
      <Flex justify="space-between" fontWeight="semibold">
        {text}
        <Text color={isZeroTotal || !remaining ? "red.500" : "inherit"}>
          {isZeroTotal ? "0" : `${asNumber(status)}/${asNumber(total)}`}
        </Text>
      </Flex>
      {showStatusBar && (
        <Box w="full" mt={1}>
          <StatusBar sections={[
            { size: isZeroTotal ? 1 : status, color: !remaining ? "red.500" : "blue.500" },
            { size: isZeroTotal ? 0 : remaining, color: "gray.200" },
          ]}
          />
        </Box>
      )}
    </ListItem>
  )
}
