import React, { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Speaker } from "sharedTypes"
import { Box, List, ListItem } from "@chakra-ui/react"
import { updateSpeakers } from "context/actions"
import * as api from "api/websites"
import { moveElement, sortByOrderListWithPosition } from "utilities/objects"
import Icon from "components/elements/Icon"
import { useQueryClient } from "react-query"
import { successToast } from "utilities/toasts"
import Header from "./Header"
import SpeakerItem from "./Speaker"
import Form from "./Speaker/Form"
import NoSpeakers from "./NoSpeakers"

type Props = {
  eventId: string
  speakers: Speaker[];
}

const Speakers = ({ eventId, speakers }: Props) => {
  const [add, setAdd] = useState(false)
  const [sortingEnabled, setSorting] = useState(false)

  const initialOrder = speakers.map((speaker) => speaker.id)

  const [order, setOrder] = useState(initialOrder)
  useEffect(() => {
    setOrder(initialOrder)
  }, [speakers])

  const queryClient = useQueryClient()

  let content

  if (speakers.length) {
    const sortedSpeakers = sortByOrderListWithPosition<Speaker>(speakers, order)

    const speakersList = sortedSpeakers.map((speaker: Speaker, index) => (
      <Draggable
        key={speaker.id}
        draggableId={speaker.id.toString()}
        index={index}
        isDragDisabled={!sortingEnabled}
      >
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            selected={snapshot.isDragging}
            display="flex"
          >
            <SpeakerItem speaker={speaker} isSorting={sortingEnabled} />
            {sortingEnabled && <Icon icon="menu" color="gray.400" size={6} ml={3} mt={5} alignSelf="flex-start" />}
          </ListItem>
        )}
      </Draggable>
    ))

    const onDragEnd = (result) => {
      const { source, destination } = result

      setOrder(moveElement(order, source.index, destination.index))
    }

    const cancelSorting = () => {
      setSorting(false)
      setOrder(initialOrder)
    }

    const saveSorting = () => {
      setSorting(false)
      api.postSpeakersOrder(eventId, order).then(() => {
        updateSpeakers(queryClient, eventId, sortedSpeakers)
        successToast({ title: "Speakers updated" })
      }).catch(() => {
        setOrder(initialOrder)
      })
    }

    content = (
      <>
        <Header
          sortingEnabled={sortingEnabled}
          onEnableSorting={() => { setSorting(true) }}
          onCancelSorting={cancelSorting}
          onSaveSorting={saveSorting}
          onAddSpeaker={() => { setAdd(true) }}
        />
        <DragDropContext {...{ onDragEnd }}>
          <Box as="section" pt={2}>
            <Droppable droppableId="speakers-droppable">
              {(provided) => (
                <List
                  spacing={8}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {speakersList}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
      </>
    )
  } else {
    // This is an ugly hack for centering the NoSpeakers component vertically with SimpleBar
    content = <Box h="calc(100vh - 200px)"><NoSpeakers onClickAdd={() => setAdd(true)} /></Box>
  }

  return (
    <>
      {content}
      {add && (
        <Form onHide={() => setAdd(false)} />
      )}
    </>
  )
}

export default Speakers
