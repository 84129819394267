import React from "react"
import {
  Box,
  Button,
  Flex, FormControl, FormLabel,
} from "@chakra-ui/react"
import TextField from "components/ReactHookForm/TextField"
import TextArea from "components/ReactHookForm/TextArea"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import Autocomplete from "components/ReactHookForm/Autocomplete"
import Icon from "components/elements/Icon"
import _ from "lodash"
import AddressFields from "components/ReactHookForm/AddressFields"
import { Control, FieldValues } from "react-hook-form"
import PhoneNumberField from "components/inputs/PhoneNumberField"
import attributeTypes from "../attributeTypes"
import { PrimaryPill } from "../../Show/AttributeList/EmailField"

type Props<T extends FieldValues> = {
  name: string
  control: Control<T, object>
  path?: string
  title?: string
  id?: number
  label?: string
  placeholder?: string
  type?: string
  options?: { label: string | React.ReactNode; value: string; }[]
  onDelete?: (id) => void
  deleted?: boolean
  enriched?: boolean
  primary?: boolean
  errorMessage?: string
}

const Attribute = <T extends FieldValues, >({
  path, name, control, title, id, label, placeholder, type, options, onDelete, deleted, enriched,
  primary, errorMessage,
}: Props<T>) => {
  if (deleted) {
    return null
  }

  let fieldComponent
  const titleOptions = type && attributeTypes[type]
    ? attributeTypes[type].titles.map((attributeTitle) => attributeTitle.toLowerCase())
    : []

  let inputName = name
  if (path) {
    inputName = `${path}.${name}`
  }

  switch (type) {
    case "textarea":
      fieldComponent = <TextArea name={inputName} control={control} />
      break
    case "checkboxes":
      fieldComponent = (
        <Box maxW={60} mt={1}>
          <RadioButtons name={inputName} control={control} options={options!} horizontal mb={0} />
        </Box>
      )
      break
    case "AddressField":
      fieldComponent = <AddressFields path={inputName} control={control} />
      break
    case "PhoneField":
      fieldComponent = <PhoneNumberField name={inputName} control={control} variant="unstyled" />
      break
    default:
      fieldComponent = (
        <TextField
          name={inputName}
          control={control}
          placeholder={placeholder}
          type={type}
          variant="unstyled"
          mb={0}
          h={6}
          color={enriched ? "orange.500" : ""}
          errorMessage={errorMessage}
        />
      )
      break
  }

  let labelElement
  if (label) {
    labelElement = label
  } else if (type === "SocialField") {
    labelElement = _.capitalize(title)
  } else {
    labelElement = (
      <Autocomplete
        name={`${path}.title`}
        control={control}
        options={titleOptions}
        color="#9e9e9e"
      />
    )
  }

  return (
    <FormControl
      borderBottomWidth="1px"
      as={Flex}
      minH={12}
      px={8}
      position="relative"
      align="start"
      justify="start"
      flexGrow={1}
      role="group"
    >
      {onDelete && (
        <Button
          position="absolute"
          left={0}
          top={0}
          onClick={() => { onDelete(id) }}
          type="button"
          minWidth={0}
          h={12}
          variant="unstyled"
          display="none"
          _groupHover={{ display: "block" }}
        >
          <Icon icon="delete-solid" color="red.600" />
        </Button>
      )}
      <FormLabel
        mb={0}
        marginEnd={0}
        w="20%"
        flexShrink={0}
        display="flex"
        alignItems="center"
        h={12}
        color="gray.500"
      >{labelElement}
      </FormLabel>
      <Flex px={6} py={3} flexGrow={1}>
        {attributeTypes[type!]?.icon && <Icon icon={attributeTypes[type!]?.icon} m={1} color="gray.300" />}
        {type === "SocialField" && <Icon size={6} mr={4} icon={title!} />}
        {fieldComponent}
        {(type === "EmailField" && primary) && <PrimaryPill />}
      </Flex>
    </FormControl>
  )
}

export default Attribute
