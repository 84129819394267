import { Subscription, SubscriptionType } from "sharedTypes"

export default (subscription: Subscription | null, requiredPlan: SubscriptionType) => {
  if (!subscription) {
    return requiredPlan
  }

  const subscriptionTypes = [
    SubscriptionType.Free,
    SubscriptionType.Professional,
    SubscriptionType.Corporate,
    SubscriptionType.Enterprise,
  ]

  const requiredSubscriptionIndex = subscriptionTypes.findIndex((type) => type === requiredPlan)
  const currentPlanIndex = subscriptionTypes.findIndex((type) => type === subscription.type)

  if (requiredSubscriptionIndex > currentPlanIndex) {
    return requiredPlan
  }

  return null
}
