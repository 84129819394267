import React, { ElementType, ReactNode } from "react"
import {
  BorderProps, Box, Flex, Text,
} from "@chakra-ui/react"
import pluralize from "pluralize"
import PaginationButtons from "./PaginationButtons"
import Spinner from "./elements/Spinner"

type Props<T> = {
  items: T[]
  totalCount?: number
  Component: ElementType
  isPreviousData: boolean
  page: number
  onChangePage: (page: number) => void
  isLoading: boolean
  entityName?: string
  borderWidth?: BorderProps["borderWidth"]
  additionalHeader?: ReactNode
}

const PaginatedView = <T extends object>({
  items,
  totalCount = 0,
  Component,
  page,
  onChangePage,
  isLoading,
  isPreviousData,
  entityName,
  borderWidth = 1,
  additionalHeader,
}: Props<T>) => {
  const itemsPerPage = 25
  const totalPages = Math.ceil(totalCount / itemsPerPage)
  const currentPageLength = items.length
  const from = itemsPerPage * (page - 1) + 1
  const to = from + currentPageLength - 1

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box borderWidth={borderWidth} borderColor="gray.300" mb={4}>
      <Flex
        flexDirection="column"
        position="sticky"
        top={0}
      >
        {additionalHeader}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="gray.100"
          borderColor="gray.300"
          borderBottomWidth={1}
          h={10}
          px={4}
        >
          <Flex>
            <Box mr={2}>
              <Text>{totalCount === 0 ? 0 : `${from} - ${to} of ${totalCount}`} {entityName && pluralize(entityName, totalCount)}</Text>
            </Box>
            {isPreviousData && <Spinner heightFull size="md" />}
          </Flex>
          {totalCount > 0 && (
            <PaginationButtons
              currentPage={page}
              pageCount={totalPages}
              onChangePage={onChangePage}
              showPageInfo
              totalCount={totalCount}
              currentPageLength={currentPageLength}
            />
          )}
        </Flex>
      </Flex>
      {items.map((renderData, index) => <Component key={index} {...renderData} />)}
    </Box>
  )
}

export default PaginatedView
