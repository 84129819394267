import { Box } from "@chakra-ui/react"
import _ from "lodash"
import React, { ReactNode, useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"

type Props = {
  children: ReactNode
  onContentScrollEnd?: () => void
}

const ScrollView = ({ children, onContentScrollEnd }: Props) => {
  const ref = useRef(null)
  const scrollableNodeRef = useRef(null)

  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
      onContentScrollEnd!()
    }
  }

  const debouncedHandleScroll = _.debounce(handleScroll, 100)

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.contentEl.style.height = "100%"
    }
  }, [ref])

  return (
    <Box
      position="relative"
      overflowX="hidden"
      as={SimpleBar}
      h="full"
      w="full"
      flexGrow={1}
      ref={ref}
      scrollableNodeProps={{
        onScroll: (e) => {
          if (onContentScrollEnd) {
            e.persist()
            debouncedHandleScroll(e)
          }
        },
        ref: scrollableNodeRef,
      }}
    >
      {children}
    </Box>
  )
}

export default ScrollView
