import React, {
  useCallback, useRef, useState,
} from "react"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"
import { postPerson, postGuest } from "api/guests"
import { refetchGuests } from "context/actions"
import { getPeople } from "api/people"
import {
  Avatar, Box, Flex, SimpleGrid, Text,
} from "@chakra-ui/react"
import { debounce } from "lodash"
import useHover from "ahooks/lib/useHover"
import { useQueryClient } from "react-query"
import { useShallow } from "zustand/shallow"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import { EMAIL_VALIDATION_REGEX, SENDER_PHONE_NUMBER } from "utilities/constants"
import { useEventGuestPaths } from "utilities/routes"
import PhoneNumberField from "components/inputs/PhoneNumberField"
import validatePhoneNumber from "utilities/validatePhoneNumber"
import useGuestsStore from "../services/useGuestsStore"

interface Props {
  eventId: string
  parentId: string
  onHide: () => void
}

const NewGuest = ({ eventId, parentId, onHide }: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [suggestions, setSuggestions] = useState([] as any)
  const { reset } = useGuestsStore(
    useShallow((state) => ({
      reset: state.reset,
    })),
  )

  const { eventGuestPath } = useEventGuestPaths()

  const createGuest = (params) => (params.personId ? createExisting(params) : createNew(params))
  const createNew = async (params) => {
    const person = await postPerson(
      {
        person: {
          firstName: params.firstName,
          lastName: params.lastName,
          customFields: [{
            type: "EmailField",
            title: "Email",
            value: params.email,
            default: true,
          }, {
            type: "PhoneField",
            title: "phone",
            value: params.phone,
            default: true,
          }],
        },
      },
    )

    const { data: { id } } = person

    return postGuest(eventId, { personId: id, guestId: parentId })
  }
  const createExisting = ({ personId }) => postGuest(eventId, { personId, guestId: parentId })

  const onSubmit = (values) => createGuest(values).then((guest) => {
    if (!guest) {
      return
    }

    const { data } = guest
    refetchGuests(queryClient, eventId)
    reset()
    history.push(eventGuestPath(eventId, data.id))
    onHide()
  })

  const searchPeople = (value) => {
    if (value.length === 0) {
      setSuggestions([])

      return
    }

    getPeople(
      { query: value, excludeEventId: eventId },
    ).then(({ data }) => {
      setSuggestions(data || [])
    })
  }
  const debouncedSearchPeople = useCallback(debounce(searchPeople, 250), [])

  const GuestSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    email: Yup.string().matches(EMAIL_VALIDATION_REGEX, "Invalid email format"),
    phone: Yup.string()
      .test("is-valid-phone", "Phone number is not valid", (val) => (val ? validatePhoneNumber(val) : true)),
  })

  return (
    <ModalForm
      title="New Guest"
      onClose={onHide}
      validationSchema={GuestSchema}
      initialValues={{ firstName: "", lastName: "", email: "" }}
      onSubmit={onSubmit}
      submitText="Add New Guest"
      size="lg"
      h="80%"
    >
      {({
        setValue, handleSubmit, control,
      }) => {
        const onSelectSuggestion = (person) => {
          setValue("personId", person.id)
          handleSubmit(onSubmit)()
        }

        return (
          <>
            <Text fontSize="xl" fontWeight={600} mb={2}>Enter Guest Data</Text>
            <SimpleGrid columns={2} spacing={4}>
              <TextField
                name="firstName"
                control={control}
                placeholder="First Name"
                onChange={(e) => debouncedSearchPeople(e.target.value)}
              />
              <TextField
                name="lastName"
                control={control}
                placeholder="Last Name"
                onChange={(e) => debouncedSearchPeople(e.target.value)}
              />
            </SimpleGrid>
            <TextField
              name="email"
              control={control}
              placeholder="Email"
              onChange={(e) => debouncedSearchPeople(e.target.value)}
            />
            <PhoneNumberField control={control} name="phone" placeholder={SENDER_PHONE_NUMBER} variant="flushed" />
            {suggestions.length > 0
            && <Text fontSize="xl" fontWeight={600} pt={3} mb={4}>Add Guest from Other Events</Text>}
            {suggestions.map((s) => (
              <Suggestion
                key={s.id}
                onSelectSuggestion={() => onSelectSuggestion(s)}
                {...s}
              />
            ))}
          </>
        )
      }}
    </ModalForm>
  )
}

const Suggestion = ({
  id, email, fullname, photoUrl, onSelectSuggestion,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  const isHovering = useHover(ref)

  return (
    <Flex
      ref={ref}
      key={id}
      justify="space-between"
      align="center"
      p={2}
      borderWidth={1}
      cursor="pointer"
      onClick={onSelectSuggestion}
      _hover={{ bgColor: "blue.50" }}
    >
      <Flex align="center">
        <Avatar
          mr={3}
          src={photoUrl}
          name={fullname}
        />
        <Box noOfLines={2}>
          <Text
            fontWeight="semibold"
            lineHeight="shorter"
          >{fullname}
          </Text>
          <Text lineHeight="shorter">{email}</Text>
        </Box>
      </Flex>
      {isHovering && <Text pr={3} color="blue.500" fontWeight={600}>Add Existing Guest</Text>}
    </Flex>
  )
}

export default NewGuest
