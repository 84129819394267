import React from "react"
import {
  Box, Flex, Heading, Image, Link, Text,
} from "@chakra-ui/react"
import { DioboxEvent } from "sharedTypes"
import Icon from "components/elements/Icon"
import placeholderLogo from "assets/images/placeholder-logo.svg"
import placeholderImage from "assets/images/placeholder-image.svg"

type Props = {
  event: DioboxEvent,
}

const PortalPreview = ({
  event,
}: Props) => {
  const {
    portalShowPageHeader,
    portalShowEventImage,
    portalEventImageUrl,
    portalDateTimeLocation,
    portalShowMap,
    portalMapUrl,
    emailNotificationsEventName,
    portalTopNavBackgroundColor,
    portalTopNavTextColor,
    portalPrimaryColor,
    portalBackgroundColor,
    portalLogoUrl,
    portalEventImageScale,
    portalPageHeader,
    portalShowLogo,
    translations: {
      viewMap,
    },
  } = event

  const eventImageUrl = portalEventImageUrl ? `${portalEventImageUrl}?w=1000&h=750&sharp=60` : placeholderImage

  let portalLogo
  if (portalShowLogo) {
    if (portalLogoUrl) {
      portalLogo = (
        <Box
          w={110}
          h={50}
          bgImage={`url('${portalLogoUrl}')`}
          bgRepeat="no-repeat"
          bgSize="contain"
          borderRadius="md"
        />
      )
    } else {
      portalLogo = (
        <Image my={3} src={placeholderLogo} />
      )
    }
  }

  return (
    <Box borderWidth={1} borderRadius="lg" overflow="hidden">
      <Flex h={5} backgroundColor="gray.500" alignItems="center" paddingX={2}>
        <Box w={1.5} h={1.5} backgroundColor="white" borderRadius="md" />
        <Box w={1.5} h={1.5} backgroundColor="white" borderRadius="md" ml={1} />
        <Box w={1.5} h={1.5} backgroundColor="white" borderRadius="md" ml={1} />
      </Flex>
      <Box backgroundColor={portalBackgroundColor}>
        <Box px={8} pt={3} pb={8} backgroundColor={portalTopNavBackgroundColor}>
          {portalLogo}
          <Box>
            {portalShowPageHeader && (
            <>
              <Text fontWeight="semibold" color={portalTopNavTextColor}>{portalPageHeader}</Text>
              <Text fontSize="sm" color={portalTopNavTextColor}>{emailNotificationsEventName}</Text>
            </>
            )}
          </Box>
        </Box>
        <Box px={8} pb={8} mt={-4}>
          <Flex
            w="100%"
            borderWidth={1}
            borderRadius="md"
            alignItems="center"
            flexDirection="column"
            overflow="hidden"
            pb={4}
            backgroundColor="white"
          >
            {portalShowEventImage && (
            <Image
              src={eventImageUrl}
              boxSize={portalEventImageUrl ? `${portalEventImageScale}%` : undefined}
            />
            )}
            <Box textAlign="center" px={8} py={5}>
              <Heading mb={3} fontSize="3xl" fontWeight="semibold">
                {emailNotificationsEventName}
              </Heading>
              <Box
                dangerouslySetInnerHTML={{ __html: portalDateTimeLocation }}
                mb={3}
                fontSize="lg"
              />
            </Box>
            {portalShowMap && (
            <Box mt={2}>
              <Link href={portalMapUrl} isExternal>
                <Text textAlign="center" fontSize="xs" color={portalPrimaryColor}><Icon icon="event-where" color={portalPrimaryColor} mr={1} />{viewMap}</Text>
              </Link>
            </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default PortalPreview
