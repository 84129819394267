import React, { useCallback, useState } from "react"
import Link from "components/elements/Link"
import { useHistory } from "react-router-dom"
import {
  DioboxEvent,
  Email as EmailType,
  ModalName as ModalType,
  User,
  AccountUser,
  Form,
  ModalName,
} from "sharedTypes"
import {
  eventEmailPath,
  eventEmailSetupPath,
  eventEmailEmailPath,
  eventEmailReviewPath,
  eventEmailRecipientsPath,
} from "utilities/routes"
import FullScreenModalNav from "components/dialogs/FullScreenModalNav"
import { removeFalsy } from "utilities/objects"
import Modal from "components/dialogs/Modal"
import pluralize from "pluralize"
import { Flex, HStack, Text } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import useCurrentAccount from "services/useCurrentAccount"
import { DEFAULT_SENDER_EMAIL } from "utilities/enums"
import { useIntercom } from "react-use-intercom"
import { asDate } from "utilities/strings"
import Icon from "components/elements/Icon"
import { resendConfirmationInstructions } from "api/sessions"
import { successToast } from "utilities/toasts"
import NavLink from "components/Navigation/NavLink"
import useModal from "services/useModal"
import { BuilderBlockType } from "sharedTypes/builder"
import { useForm } from "react-hook-form"
import { useDeepCompareEffect } from "ahooks"
import _ from "lodash"
import { requiresUpgrade } from "utilities/forms"
import ConfirmationModal from "modals/ConfirmationModal"
import useFeatures from "services/useFeatures"
import Review from "./Review"
import Email from "./Email"
import Setup from "./Setup"
import Recipients from "./Recipients"

type Props = {
  event: DioboxEvent
  email: EmailType
  action: string
  updateEmail: (params: {}) => Promise<void>
  updateEvent: (params: {}) => Promise<void>
  sendEmail: () => Promise<void>
  currentUser: User
  accountUsers: AccountUser[]
  linkedForms: Form[]
  deletedFormsCount: number
}

const EmailWizard = ({
  event,
  email,
  action,
  updateEmail,
  updateEvent,
  sendEmail,
  currentUser,
  accountUsers,
  linkedForms,
  deletedFormsCount,
}: Props) => {
  const history = useHistory()
  const [sending, setSending] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [sendCount, setSendCount] = useState(null)
  const currentAccount = useCurrentAccount()
  const showModal = useModal()
  const { show: showIntercom, update: updateIntercom } = useIntercom()
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      ...email,
      fromMailbox: email.fromMailbox || event.emailNotificationsSenderMailbox,
      sendToUniqueEmails: email.sendToUniqueEmails ? "true" : "false",
      senderEmail: DEFAULT_SENDER_EMAIL,
    },
  })
  const isAtLeastOneRecipientGroupSelected = !_.isEmpty(email.sendTo)

  updateIntercom({ hideDefaultLauncher: true })
  const close = () => {
    history.push(eventEmailPath(event.id, email.id))
    updateIntercom({ hideDefaultLauncher: false })
  }

  const onSubmit = ({
    from,
    fromMailbox,
    replyTo,
    sendTo,
    sendToUniqueEmails,
    senderEmail,
    subject,
    title,
  }) => updateEmail({
    from,
    fromMailbox,
    replyTo,
    senderEmail,
    sendToUniqueEmails,
    subject,
    title,
    sendTo: removeFalsy(sendTo),
  })

  const submitForm = handleSubmit(onSubmit)

  const debouncedSave = useCallback(_.debounce(submitForm, 375), [submitForm])

  useDeepCompareEffect(() => {
    const subscription = watch(() => {
      debouncedSave()
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const onSend = () => {
    setSending(true)
    sendEmail().then(() => close())
  }

  const onConfirmSend = () => {
    if (sending || !sendCount) { return }
    setShowConfirmationModal(true)
  }

  const steps = [
    {
      title: "Setup your email",
      label: "Setup",
      path: eventEmailSetupPath(event.id, email.id),
    },
    {
      title: "Select Recipients",
      label: "Recipients",
      path: eventEmailRecipientsPath(event.id, email.id),
    },
    {
      title: "Design and edit contents of email",
      label: "Email",
      path: eventEmailEmailPath(event.id, email.id),
    },
    {
      title: "Review",
      label: "Review",
      path: eventEmailReviewPath(event.id, email.id),
    },
  ]
  const resendInstructions = () => resendConfirmationInstructions(currentUser.email)
    .then(() => successToast({ title: `Instructions sent to ${currentUser.email}` }))

  const qrBlocked = !event.qrCodesEnabled
    && email.emailData.blocks.some(({ type }) => type === BuilderBlockType.QR_CODE)

  const linksToDisabledForm = linkedForms.some((form) => form.disabled)
  const { emailsAndInvitations } = useFeatures()

  const emailDisabledReasons = () => [
    !currentAccount.emailEnabled
      ? (
        <>Email sending is disabled by default for all newly created accounts
          to prevent spammers from abusing Diobox.{" "}<Link onClick={showIntercom}>Contact us to enable email sending</Link>.
        </>
      ) : null,
    !currentUser.confirmed
      ? (
        <>Email address ({currentUser.email}) has not been confirmed.{" "}<Link onClick={resendInstructions}>Resend confirmation instructions</Link>.</>
      ) : null,
    requiresUpgrade(linkedForms, currentAccount.subscription)
      ? (
        <>
          Your subscription does not support sending this type of email.{" "}<Link onClick={() => showModal(ModalType.Upgrade)}>Upgrade your subscription</Link>.
        </>
      ) : null,
    qrBlocked
      ? (
        <>
          Your subscription does not support emails with QR Code blocks.{" "}<Link onClick={() => showModal(ModalType.Upgrade)}>Upgrade your subscription</Link>.
        </>
      ) : null,
    linksToDisabledForm ? <>Email is connected to a disabled form.</> : null,
    !emailsAndInvitations
      ? (
        <>
          Your subscription does not support sending emails.{" "}<Link onClick={() => showModal(ModalType.Upgrade)}>Upgrade your subscription</Link>.
        </>
      ) : null,
  ].filter(Boolean)

  const step = steps.findIndex(({ label }) => action === label.toLowerCase())
  const back = () => (step > 0 ? steps[step - 1].path : steps[step].path)
  const next = () => (step < steps.length - 1 ? steps[step + 1].path : steps[step].path)

  const isActionButtonDisabled = emailDisabledReasons().length > 0
  || !sendCount || deletedFormsCount > 0

  return (
    <>
      <form onSubmit={submitForm}>
        <Modal
          show
          size="full"
          noPadding
          hide={close}
          closeOnEsc={false}
          header={(
            <FullScreenModalNav type="header">
              <Text w={1 / 3} noOfLines={1}>{email.title}</Text>
              <Text fontWeight="bold">{steps[step].title}</Text>
              <HStack spacing={4} w={1 / 3} justify="flex-end">
                <Text fontSize="sm">Saved: {asDate(email.updatedAt)}</Text>
                <Link onClick={close} color="blue.300" fontWeight="bold">
                  Save and Close
                  <Icon icon="cancel-circle" ml={2} />
                </Link>
              </HStack>
            </FullScreenModalNav>
          )}
          footer={(
            <FullScreenModalNav type="footer" color="gray.400">
              <NavLink to={back()}><Icon icon="prev-arrow" size={3} mr={2} /> Back</NavLink>
              <HStack spacing={5} divider={<><Icon icon="next-arrow" size={3} mx={8} /></>}>
                {
                  steps.map(({ path, label }) => (
                    <NavLink to={path} activeProps={{ color: "white", fontWeight: "bold" }}>{label}</NavLink>
                  ))
                }
              </HStack>
              { step < steps.length - 1
                ? <NavLink to={next()}>Next <Icon icon="next-arrow" size={3} ml={2} /></NavLink>
                : (
                  <Flex gap={4}>
                    <Button
                      isDisabled={!isAtLeastOneRecipientGroupSelected}
                      onClick={() => showModal(
                        ModalName.ScheduleSendEmail, { email, onSave: close },
                      )}
                      colorScheme="button.light"
                    >Schedule
                    </Button>
                    <Button
                      isDisabled={isActionButtonDisabled}
                      onClick={onConfirmSend}
                    >Send Now
                    </Button>
                  </Flex>
                )}
            </FullScreenModalNav>
          )}
        >
          { steps[step].label === "Setup" && <Setup event={event} accountUsers={accountUsers} control={control} /> }
          { steps[step].label === "Recipients" && <Recipients event={event} control={control} updateEvent={updateEvent} /> }
          { steps[step].label === "Email" && <Email email={email} onChange={updateEmail} /> }
          { steps[step].label === "Review" && (
          <Review
            event={event}
            email={email}
            sendCount={sendCount}
            setSendCount={(count) => setSendCount(count)}
            emailDisabledReasons={emailDisabledReasons()}
            forms={linkedForms}
            deletedFormsCount={deletedFormsCount}
          />
          ) }
          {showConfirmationModal && (
            <ConfirmationModal
              onClose={() => setShowConfirmationModal(false)}
              onSubmit={onSend}
              isLoading={sending}
              text={`You are about to send your invitation to ${sendCount} ${pluralize("guests", sendCount ?? 0)}.`}
            />
          )}
        </Modal>
      </form>
    </>
  )
}

export default EmailWizard
