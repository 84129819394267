import { DioboxEvent, MergeTagReplacement, Plan } from "sharedTypes"
import React, { ReactElement } from "react"
import numeral from "numeral"
import { CURRENCY_OPTIONS } from "utilities/enums"
import {
  format, formatDistanceToNow, formatDistanceToNowStrict, isToday,
  isYesterday,
} from "date-fns"
import { toZonedTime, format as formatWithTZ } from "date-fns-tz"

export const UUID = "([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"
export const INTEGER = "(\\d+)"

export const slugify = (string) => string
  .toString()
  .trim()
  .toLowerCase()
  .replace(/\s+/g, "-")
  .replace(/[^\w-]+/g, "")
  .replace(/--+/g, "-")
  .replace(/^-+/, "")
  .replace(/-+$/, "")

export const titleize = (string) => string
  .replace(/([^A-Z])([A-Z])/g, "$1 $2") // split cameCase
  .replace(/[_-]+/g, " ") // split snake_case and lisp-case
  .toLowerCase()
  .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase()) // title case words
  .replace(/\s+/g, " ") // collapse repeated whitespace
  .replace(/^\s+|\s+$/, "") // remove leading/trailing whitespace

export const removeProtocolFromUrl = (url) => url.replace(/(^\w+:|^)\/\//, "")

export const hasValidCustomDomain = ({ customDomain, whiteLabel }: DioboxEvent) => (
  whiteLabel && customDomain?.validSigning
)

export const customDomainSender = (event: DioboxEvent, fromMailbox?: string | null) => (
  hasValidCustomDomain(event) && event.emailNotificationsSenderMailbox
    ? `${(fromMailbox ?? event.emailNotificationsSenderMailbox)}@${event.customDomain?.domain}`
    : null
)

export const joinWithBr = (parts) => parts
  .filter(Boolean)
  .reduce(
    (all: (string|ReactElement)[], current: string, index: number) => [...all, React.createElement("br", { key: index }), current],
    [],
  )
  .slice(1)

export const firstName = (fullName: string) => {
  if (!fullName.includes(" ")) {
    return fullName
  }

  return fullName.split(" ")[0]
}
export const lastName = (fullName: string) => {
  if (!fullName.includes(" ")) {
    return ""
  }

  return fullName.split(" ").slice(1).join(" ")
}
export const asNumber = (number: number) => numeral(number).format("0,0")
export const asMoney = (number: number|string, currency:string = "USD", truncate?: boolean) => {
  const symbol = CURRENCY_OPTIONS.find((c) => c.value === currency)?.symbol

  return symbol + numeral(number).format(truncate ? "0,0" : "0,0.00")
}
export const asPercent = (number: number) => numeral(number).format("0%")
export const asDateWithoutTime = (date: string): string => date.split("T")[0]
export const asDate = (date: string|Date, f?: string) => {
  if (!date) { return null }

  return format(typeof date === "string" ? new Date(date) : date, f || "PP, p")
}
export const asDateWithTZ = (date: string, timezone: string, f?: string) => {
  if (!date) { return null }

  const zonedDate = toZonedTime(new Date(date), timezone)

  return formatWithTZ(zonedDate, f || "PP, p", { timeZone: timezone })
}
export const asDateWithoutTZ = (date: string) => asDate(new Date(Date.parse(`${date} 00:00`) || Date.parse(date)), "PP")
export const asDateDistance = (date: string, strict: boolean = false) => {
  const fn = strict ? formatDistanceToNowStrict : formatDistanceToNow

  return fn(new Date(date), { addSuffix: true })
}
export const generateFormatStringWithYesterdayAndToday = (date: string) => {
  const convertedDate = new Date(date)

  if (isToday(convertedDate)) {
    return "'Today,' h:mm a"
  } if (isYesterday(convertedDate)) {
    return "'Yesterday,' h:mm a"
  }

  return undefined
}
export const isUUID = (value: string) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(value)
}
export const stripHtmlTags = (input: string) => input.replace(/<[^>]*>/g, "")
export const replaceMergeTagsInText = (
  text: string, replacements: MergeTagReplacement[],
): string => replacements.reduce((updatedText, { mergeTag, value }) => {
  const pattern = new RegExp(mergeTag, "g")

  return updatedText.replace(pattern, value)
}, text)
export const annualDiscount = (interval: "year" | "month", selectedPlan: Plan) => (interval === "year" ? selectedPlan.monthlyPrice * 12 - selectedPlan.annualPrice : 0)
