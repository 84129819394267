export default {
  EmailField: {
    icon: "emails",
    titles: ["Email", "Home", "Work"],
  },
  PhoneField: {
    titles: ["Phone", "Mobile", "Home", "Work"],
  },
  AddressField: {
    icon: "event-where",
    titles: ["Address", "Home", "Work"],
  },
  TextField: {
    icon: "contacts-text",
    titles: ["Text"],
  },
  SocialField: {
    titles: ["LinkedIn", "Twitter", "Facebook", "Instagram", "Flickr", "Pinterest", "Homepage"],
  },
}
