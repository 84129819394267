import React from "react"
import AlertBox, { AlertBoxType } from "components/elements/AlertBox/AlertBox"

const PendingTextingVerificationAlert = ({ contactUs }) => (
  <AlertBox
    type={AlertBoxType.Verification}
    text="Text sending is disabled by default for all newly created accounts to prevent spammers from abusing Diobox. You can still build your campaigns on this page."
    header="Your account is pending verification for text sending"
    buttonText="Contact Us to Enable Text Sending"
    onClick={contactUs}
  />
)

export default PendingTextingVerificationAlert
