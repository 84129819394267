import React, { forwardRef } from "react"
import ReactPhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { Control, FieldValues, useController } from "react-hook-form"
import { Box, Input, InputProps } from "@chakra-ui/react"

type Props<T extends FieldValues> = {
  control: Control<T, object>
  name: string
  placeholder?: string
  variant?: "outline" | "flushed" | "unstyled"
};

const PhoneNumberField = <T extends FieldValues, >({
  control,
  name,
  placeholder,
  variant,
}: Props<T>) => {
  const { field } = useController<any>({ name, control })

  let customInput
  switch (variant) {
    case "unstyled": {
      customInput = UnstyledInput
      break
    }
    case "flushed": {
      customInput = FlushedInput
      break
    }
    default: {
      customInput = OutlineInput
      break
    }
  }

  return (
    <Box mb={4}>
      <ReactPhoneInput
        {...field}
        placeholder={placeholder}
        onChange={(value) => field.onChange(value)}
        inputComponent={customInput}
        international
      />
    </Box>
  )
}

const OutlineInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input ref={ref} {...props} />
))

const FlushedInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input ref={ref} {...props} variant="flushed" />
))

const UnstyledInput = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(
  (props, ref) => <input ref={ref} {...props} />,
)

export default PhoneNumberField
