import React from "react"
import Button from "components/Buttons/Button"
import CancelButton from "components/Buttons/CancelButton"
import { Flex, Text } from "@chakra-ui/react"

type Props = {
  sortingEnabled: boolean;
  onEnableSorting: () => void;
  onCancelSorting: () => void;
  onSaveSorting: () => void;
}

const EditOrder = ({
  sortingEnabled,
  onEnableSorting,
  onCancelSorting,
  onSaveSorting,
}: Props) => {
  if (sortingEnabled) {
    return (
      <Flex alignItems="center" gap={4}>
        <Text color="gray.500">Set the order of your forms on the Guest Portal</Text>
        <CancelButton size="sm" onClick={onCancelSorting} />
        <Button size="sm" onClick={onSaveSorting}>Save</Button>
      </Flex>
    )
  }

  return <Button variant="outline" size="sm" onClick={onEnableSorting}>Edit Order</Button>
}

export default EditOrder
