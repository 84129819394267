import React, { useState } from "react"
import {
  DioboxEvent, Guest, ModalName,
} from "sharedTypes"
import Spinner from "components/elements/Spinner"
import {
  Box, Flex, Stack, Text,
} from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import { SENDER_PHONE_NUMBER } from "utilities/constants"
import { usePaginatedTextMessages } from "queries/texts"
import { useHistory } from "react-router-dom"
import { sendTextMessage } from "api/texts"
import { getGuestPhoneNumber } from "utilities/objects"
import GuestAvatar from "components/elements/GuestAvatar"
import EmailOrTextMessageListItem from "components/EmailOrTextMessageListItem"
import Link from "components/elements/Link"
import useModal from "services/useModal"
import Icon from "components/elements/Icon"
import useMergeTags from "services/useMergeTags"
import { asDate } from "utilities/strings"
import { refreshGuest } from "context/actions"
import { useQueryClient } from "react-query"
import ScrollView from "components/layout/ScrollView"
import TextUpgradeAlert from "pages/EventShow/Texts/TextUpgradeAlert"

type Props = {
  onClose: () => void
  guest: Guest
  event: DioboxEvent
}

const SendTextMessage = ({
  onClose,
  guest: {
    id,
    externalId: guestExternalId,
    personInfo: {
      firstName,
      lastName,
      photoUrl,
    },
    status,
    checkedIn,
  },
  guest,
  event: {
    id: eventId,
    title,
    startDate,
    textsEnabled,
  },
}: Props) => {
  const { isLoading: eventIsLoading, data: currentEvent } = useCurrentEvent()
  const history = useHistory()
  const { isLoading: textMessagesAreLoading, fetchNextPage, texts } = usePaginatedTextMessages(
    currentEvent.id,
  )
  const [selectedTextMessageExternalId, setSelectedTextMessageExternalId] = useState<string>()
  const showModal = useModal()
  const { replaceMergeTags } = useMergeTags({ eventId, guestExternalId })
  const queryClient = useQueryClient()

  const phoneNumberField = getGuestPhoneNumber(guest)

  if (!texts || !phoneNumberField) {
    return null
  }

  const recipientPhone = `${phoneNumberField.value}`

  const handleSubmit = async () => {
    if (!selectedTextMessageExternalId) {
      return
    }

    await sendTextMessage(currentEvent.id, selectedTextMessageExternalId, [id])
    // Can't use addGuestTransaction() because here we got a text message, need to manually refresh
    refreshGuest(queryClient, guest.id)
    history.goBack()
    onClose()
  }

  const handleCreateNewTextMessage = () => showModal(ModalName.NewTextMessage)

  return (
    <ModalForm
      onSubmit={handleSubmit}
      submitText="Send"
      onClose={onClose}
      title="Send Text Message"
      size="md"
      submitIsDisabled={!textsEnabled}
      noPadding={!selectedTextMessageExternalId}
      hideActions={!selectedTextMessageExternalId}
    >
      {() => {
        if (eventIsLoading || textMessagesAreLoading) {
          return <Spinner heightFull />
        }

        if (texts.length === 0) {
          return (
            <>
              <Flex direction="column" justify="center" align="center" py={8}>
                <Icon icon="texts" size={12} />
                <Text fontSize="2xl" my={2}>No Available Text Messages</Text>
                <Text mb={4} px={12} textAlign="center">
                  After you create at least one Text Message,
                  you will be able to resend it to additional guests here.
                </Text>
                {textsEnabled ? (
                  <Link onClick={handleCreateNewTextMessage}>
                    Create New Text Message
                  </Link>
                ) : <Box px={8}><TextUpgradeAlert /></Box>}
              </Flex>
            </>
          )
        }

        if (!selectedTextMessageExternalId) {
          return (
            <Box>
              {!textsEnabled && <Box p={8}><TextUpgradeAlert /></Box>}
              <ScrollView onContentScrollEnd={fetchNextPage}>
                {texts.map(({ externalId, name, sentAt }) => (
                  <EmailOrTextMessageListItem
                    key={externalId}
                    title={name}
                    icon="text-sms"
                    sentOn={sentAt}
                    onClick={() => setSelectedTextMessageExternalId(externalId)}
                    disableDraftItems
                  />
                ))}
              </ScrollView>
            </Box>
          )
        }

        const bodyTemplate = texts.find(
          ({ externalId }) => externalId === selectedTextMessageExternalId,
        )?.body

        if (!bodyTemplate) {
          return null
        }

        const textBody = replaceMergeTags(bodyTemplate, title, asDate(startDate)!, guest)

        return (
          <Stack spacing={6}>
            <Flex alignItems="center">
              <Text fontWeight="bold" w="25%">To</Text>
              <Flex gap={2} alignItems="center">
                <GuestAvatar
                  firstName={firstName}
                  lastName={lastName}
                  photoUrl={photoUrl}
                  status={status}
                  checkedIn={!!checkedIn}
                />
                <Box>
                  <Text>{firstName} {lastName}</Text>
                  <Text>{recipientPhone}</Text>
                </Box>
              </Flex>
            </Flex>
            <Flex>
              <Text fontWeight="bold" w="25%">From</Text>
              <Text>{SENDER_PHONE_NUMBER}</Text>
            </Flex>
            <Flex flexDirection="column" gap={3} pt={3}>
              <Text fontWeight="bold">Message</Text>
              <Text borderWidth={1} borderRadius="lg" px={4} py={2} whiteSpace="pre-line">{textBody}</Text>
            </Flex>
          </Stack>
        )
      }}
    </ModalForm>
  )
}

export default SendTextMessage
