import { useCurrentEvent } from "queries/events"
import { getEnabledFormTypesBySubscriptionLevel } from "utilities/forms"
import { FormType } from "sharedTypes"
import useCurrentPlanType from "utilities/useCurrentPlanType"
import useCurrentAccount from "./useCurrentAccount"

const useFeatures = () => {
  const { subscription } = useCurrentAccount()
  const { data: currentEvent } = useCurrentEvent()
  const { onFreePlan } = useCurrentPlanType()

  const enabledFormTypes = getEnabledFormTypesBySubscriptionLevel(subscription)

  const {
    publicEmailsEnabled,
    qrCodesEnabled,
    checkInLocationsEnabled,
    whiteLabel,
    badgePrintingEnabled,
    segmentsEnabled,
  } = currentEvent || {}

  return {
    guestCheckIns: true,
    emailsAndInvitations: publicEmailsEnabled,
    qrCodeDistribution: qrCodesEnabled,
    eventSessionsAndActivities: checkInLocationsEnabled,
    customFilters: segmentsEnabled,
    registrationForms: enabledFormTypes.includes(FormType.Registration),
    ticketingAndPayments: true,
    rsvpForms: enabledFormTypes.includes(FormType.RSVP),
    waitlistForms: enabledFormTypes.includes(FormType.Waitlist),
    customEmailDomains: whiteLabel,
    tablesAndSeating: !onFreePlan,
    websiteAndAgenda: !onFreePlan,
    badgePrinting: badgePrintingEnabled,
    whiteLabel,
  }
}

export default useFeatures
