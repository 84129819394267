import React from "react"
import {
  Box, Flex, Heading, Stack, Text, VStack,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import { eventFormsPath } from "utilities/routes"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import RadioButtons, { RadioOptionLabel } from "components/ReactHookForm/RadioButtons"
import Spinner from "components/elements/Spinner"
import { useForms } from "queries/forms"
import { DioboxEvent, FormType } from "sharedTypes"
import Select from "components/ReactHookForm/Select"
import FormListItem from "./FormListItem"

type Props = {
  behavior: string | null
  updateWebsite: (params: {}) => Promise<void>
  event: DioboxEvent
}

const ActionV2 = ({
  behavior, updateWebsite, event, event: { id: eventId },
}: Props) => {
  const { isLoading, data: forms } = useForms(eventId)

  const handleSubmit = ({ action, formId }) => {
    let actionBehavior
    switch (action) {
      case "":
        actionBehavior = null
        break
      case "form":
        actionBehavior = formId
        break
      default:
        actionBehavior = action
    }

    return updateWebsite({ actionBehavior })
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!forms) {
    return null
  }

  const enabledNonRSVPForms = forms.data.filter(
    ({ disabled, type, private: isPrivate }) => !disabled && type !== FormType.RSVP && !isPrivate,
  )

  const initialFormData = () => {
    const initialFormId = enabledNonRSVPForms.length > 0
      ? enabledNonRSVPForms[0].externalId : null

    if (behavior !== null && behavior !== "forms") {
      return { action: "form", formId: behavior }
    }

    if (behavior === null) {
      return { action: "", formId: initialFormId }
    }

    return { action: "forms", formId: initialFormId }
  }

  return (
    <Box>
      <Heading mb={2} fontSize="lg">Action Button</Heading>
      <Text mb={6}>
        Include a button on your Diobox Webpage to facilitate the registration process using one or
        more of your <Link to={eventFormsPath(eventId)}>Forms</Link>.
      </Text>
      <ContainedForm
        values={initialFormData()}
        onSubmit={handleSubmit}
      >
        {({ control, watch }) => {
          const action = watch("action")

          const enableSingleFormSelect = action === "form"
          const singleFormSelect = (
            <Flex w="100%" pl={7}>
              <Select
                control={control}
                name="formId"
                disabled={!enableSingleFormSelect}
                options={enabledNonRSVPForms.map(
                  ({ externalId, name }) => (
                    { label: name, value: externalId }
                  ),
                )}
              />
            </Flex>
          )

          const options = [
            {
              label: <RadioOptionLabel
                title="No Action"
                description={(
                  <RadioLabelDescription>
                    <Text>
                      Hide the action button on your Diobox Event Page.
                    </Text>
                  </RadioLabelDescription>
                  )}
              />,
              value: "",
            },
            {
              label: <RadioOptionLabel
                title="Launch a Single Form"
                description=""
              />,
              value: "form",
              additionalElement: singleFormSelect,
            },
            {
              label: <RadioOptionLabel
                title="Show Multiple Forms"
                description={(
                  <RadioLabelDescription>
                    <Text>
                      Display all public forms listed on the Guest Portal so that users can
                      submit multiple submissions (or place multiple ticket orders) in a single
                      transaction. To hide a form, set it’s “Visibility” field to Private.
                    </Text>
                  </RadioLabelDescription>
                )}
              />,
              value: "forms",
            },
          ]

          return (
            <Box pt={4}>
              <RadioButtons
                spacing={6}
                name="action"
                control={control}
                options={options}
              />
              <VStack mt={5} ml={7} align="flex-start" spacing={7}>
                {enabledNonRSVPForms.map(
                  (form) => <FormListItem key={form.externalId} event={event} form={form} />,
                )}
              </VStack>
            </Box>
          )
        }}
      </ContainedForm>
    </Box>
  )
}

const RadioLabelDescription = ({ children }) => (
  <Stack mt={2} spacing={2}>{children}</Stack>
)

export default ActionV2
