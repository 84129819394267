import React from "react"
import {
  Stack, Text, Flex, Box,
} from "@chakra-ui/react"
import Card from "components/layout/Card"
// import Button from "components/Buttons/Button"
import CodeBlock from "components/elements/CodeBlock"
import {
  DioboxEvent,
  //  ModalName, User,
} from "sharedTypes"
// import useModal from "services/useModal"
// import { Mode } from "modals/InviteExhibitorOrAssignLicense"
import { EventUserRole } from "services/permissions"
import { asMoney } from "utilities/strings"
import { leadRetrievalRegisterPath } from "utilities/routes"
import Link from "components/elements/Link"
import { LEAD_RETRIEVAL_HELP_ARTICLE_LINK } from "utilities/externalLinks"
import SectionHeader from "../SectionHeader"
import RoundedList from "../RoundedList"
import ExhibitorListItem from "./ExhibitorListItem/ExhibitorListItem"
import NoLeadRetrievalUserFound from "./NoLeadRetrievalUserFound"
import LeadRetrievalUpgradeAlert from "./LeadRetrievalUpgradeAlert"

type Props = {
  event: DioboxEvent
}

const LeadRetrieval = ({
  event: {
    externalId,
    users,
    leadRetrievalLicenseFee,
    currency,
    leadRetrievalEnabled,
  },
}: Props) => {
  // const showModal = useModal()

  const exhibitors = users.filter(({ role }) => role === EventUserRole.LeadRetrieval).map(
    ({ name, email, photoUrl }) => ({
      name, email, photoUrl,
    }),
  )
  const exhibitorSignUpPageUrl = `${import.meta.env.VITE_BASE}${leadRetrievalRegisterPath()}/${externalId}`

  return (
    <Stack spacing={12}>
      <Stack spacing={3}>
        <SectionHeader>Lead Retrieval</SectionHeader>
        <Text>
          Empower your exhibitors and sponsors to easily capture contact information and generate
          leads at your event—simply by scanning guest QR codes with a dedicated app right from
          their own mobile devices.
        </Text>
        <Text>
          To learn more, see: {" "}
          <Link isExternal href={LEAD_RETRIEVAL_HELP_ARTICLE_LINK}>
            Lead Retrieval Help Articles
          </Link>.
        </Text>
        {leadRetrievalEnabled && (
        <Card>
          <Stack spacing={7}>
            <Flex justifyContent="space-between" alignItems="center">
              <Stack>
                <Text fontSize="lg" fontWeight="semibold">License Fee</Text>
                <Text>The cost per license set by and paid to Diobox</Text>
              </Stack>
              <Flex borderWidth={1} w={28} h={12} alignItems="center" justifyContent="center" backgroundColor="blue.50">
                <Text fontWeight="bold">{asMoney(leadRetrievalLicenseFee, currency, true)}</Text>
              </Flex>
            </Flex>
          </Stack>
        </Card>
        )}
      </Stack>
      {!leadRetrievalEnabled
        ? <LeadRetrievalUpgradeAlert />
        : (
          <>
            <Stack spacing={3}>
              <SectionHeader actionButtons={[
              // <Button
              //   onClick={() => showModal(
              //     ModalName.InviteExhibitorOrAssignLicense, { mode: Mode.InviteExhibitor },
              //   )}
              // >Invite Exhibitor to Purchase
              // </Button>,
              ]}
              >License Purchase
              </SectionHeader>
              <Text>
                Invite your exhibitors and other lead retrieval users to purchase their own
                licenses.
              </Text>
              <Card>
                <Stack spacing={7}>
                  <Text>This process directs your exhibitors to a dedicated page where they can
                    create an account and purchase a Lead Retrieval app license. Once their
                    account is set up, both you and the exhibitor will receive a confirmation
                    notification with instructions to download the app.
                  </Text>
                  <Box>
                    <Text fontWeight="semibold">
                      Exhibitor Sign Up Page
                    </Text>
                    <CodeBlock copiable>
                      {exhibitorSignUpPageUrl}
                    </CodeBlock>
                  </Box>
                </Stack>
              </Card>
            </Stack>
            <Stack spacing={3}>
              <SectionHeader>Exhibitors</SectionHeader>
              {exhibitors.length === 0
                ? <NoLeadRetrievalUserFound />
                : (
                  <RoundedList>
                    {exhibitors.map((exhibitor) => (
                      <ExhibitorListItem
                        key={exhibitor.email}
                        exhibitor={exhibitor}
                      />
                    ))}
                  </RoundedList>
                )}
            </Stack>
          </>
        )}

    </Stack>
  )
}

export default LeadRetrieval
