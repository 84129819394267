import React, { useState } from "react"
import Button from "components/Buttons/Button"
import {
  Badge, Box, Flex, Spacer, Stack, Text,
} from "@chakra-ui/react"
import { CustomDomain, DioboxEvent, DomainStatusValue } from "sharedTypes"
import { titleize } from "utilities/strings"
import Menu, { MenuDivider, MenuItem } from "components/dialogs/Menu"
import CustomDomainForm from "pages/EventShow/Settings/CustomDomainForm"
import { deleteCustomDomain } from "api/events"
import Confirm from "components/dialogs/Confirm/Confirm"
import CustomDomainInstructions from "pages/EventShow/Settings/CustomDomainInstructions"
import Icon from "components/elements/Icon"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { removeCustomDomain } from "context/actions"
import Link from "components/elements/Link"
import useCurrentAccount from "services/useCurrentAccount"
import { useQueryClient } from "react-query"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { DIOBOX_CUSTOM_EMAIL_DOMAIN_VERIFICATION_ARTICLE_LINK } from "utilities/externalLinks"
import SectionHeader from "./SectionHeader"
import RoundedList from "./RoundedList"

const CustomDomains = ({ event, updateEvent, enabled }) => {
  const queryClient = useQueryClient()
  const [showForm, setShowForm] = useState(false)
  const [selectedDomainId, setSelectedDomainId] = useState<number|null>(null)

  const { customDomains } = useCurrentAccount()
  const unverifiedAndPendingDomains = customDomains.filter(
    (domain) => !domain.verifiedAt || getDomainStatus(domain).value === DomainStatusValue.Pending,
  )
  const hasLegacyDomains = Boolean(
    customDomains.filter(({ legacyAuthenticated }) => legacyAuthenticated).length,
  )

  const onSetSending = (domain) => {
    updateEvent({ customDomainId: domain.id === -1 ? null : domain.id })
  }

  const onDelete = (domain) => {
    deleteCustomDomain(event.id, domain.id).then(() => removeCustomDomain(queryClient, domain))
  }

  const handleViewInstructions = (domain: CustomDomain) => setSelectedDomainId(domain.id)

  return (
    <Stack spacing={4}>
      <SectionHeader
        actionButtons={[
          <Button isDisabled={!enabled} leftIcon="plus" onClick={() => setShowForm(true)}>Add Domain</Button>,
        ]}
      >Custom Email Domains
      </SectionHeader>
      <Text>
        A custom domain allows you to send emails from your own business email address. This process
        authenticates your domain name so that your recipients will only see your email address,
        instead of the Diobox email address. Once verified, your recipients will have greater
        confidence that emails were sent from you and it also reduces the chance that your messages
        will be marked as junk mail.
      </Text>
      <DomainList
        enabled={enabled}
        domains={customDomains}
        event={event}
        onSetSending={onSetSending}
        onViewInstructions={handleViewInstructions}
        onDelete={onDelete}
      />
      {hasLegacyDomains && <DomainActionRequired />}
      {unverifiedAndPendingDomains.length > 0
      && (
      <UnverifiedDomains
        domains={unverifiedAndPendingDomains}
        onViewInstructions={handleViewInstructions}
      />
      )}
      {selectedDomainId && (
      <CustomDomainInstructions
        eventId={event.id}
        domain={customDomains.find((d) => d.id === selectedDomainId)!}
        onHide={() => setSelectedDomainId(null)}
      />
      )}
      {!enabled && (
      <>
        <Spacer />
        <UpgradeAlert text="This feature requires a Corporate Subscription." />
      </>
      )}
      {showForm && <CustomDomainForm eventId={event.id} onClose={() => setShowForm(false)} />}
    </Stack>
  )
}

interface DomainListProps {
  enabled: boolean
  domains: CustomDomain[]
  event: DioboxEvent
  onSetSending: (domain: CustomDomain) => void
  onViewInstructions: (domain: CustomDomain) => void
  onDelete: (domain: CustomDomain) => void
}

const DomainList = ({
  enabled, domains, event, onSetSending, onViewInstructions, onDelete,
}: DomainListProps) => {
  const DEFAULT_DOMAIN = "diobox.com"

  const sendingDomain = () => domains.find(
    (d) => d.validSigning && d.id === event.customDomain?.id,
  ) || domains.find((d) => d.domain === DEFAULT_DOMAIN)

  const menuItems = (domain) => {
    const items = [
      <Confirm
        confirmationTitle="Set New Sending Domain"
        confirmationText={(
          <>
            <Text mb={6}>
              All emails will be sent
              from <Text as="strong" fontWeight="bold">{domain.domain}</Text>.
            </Text>
            <Text>
              To change the Sender Email for automated confirmation messages, refer to
              the <strong>Guest Portal &gt; Email Responders</strong>.
            </Text>
          </>
        )}
        confirmButtonLabel="Change Domain"
        confirmButtonColorScheme="button.primary"
      >
        <MenuItem
          isDisabled={!domain.validSigning || domain === sendingDomain()}
          onClick={() => onSetSending(domain)}
        >Set as Sending Domain
        </MenuItem>
      </Confirm>,
    ]

    const { domain: domainName } = domain

    if (domainName !== DEFAULT_DOMAIN) {
      items.push(
        <MenuItem
          onClick={() => onViewInstructions(domain)}
        >View Verifications Instructions
        </MenuItem>,
        <MenuDivider />,
        <ConfirmDelete
          type="Custom Domain"
          name={domainName}
          from="your account"
          description="Any event that uses this domain will start sending emails from diobox.com."
        >
          <MenuItem onClick={() => onDelete(domain)} color="red.700">Delete</MenuItem>
        </ConfirmDelete>,
      )
    }

    return items
  }

  if (!domains.find((d) => d.domain === DEFAULT_DOMAIN)) {
    domains.unshift({
      id: -1,
      domain: DEFAULT_DOMAIN,
      dkim: { error: null, valid: true },
      dkim2: { error: null, valid: true },
      dmarc: { error: null, valid: true },
      spf: { error: null, valid: true },
      verifyTxtKey: "",
      legacyAuthenticated: false,
      lastTestedAt: null,
      verifiedAt: "verifiedAt",
      validSigning: true,
      mailbox: "",
    })
  }

  return (
    <RoundedList>
      <Flex px={6} py={3} justify="space-between" align="center">
        <Text
          fontWeight="semibold"
          w={1 / 3}
        >DOMAIN
        </Text>
        <Text
          fontWeight="semibold"
          w={1 / 3}
        >VERIFICATION
        </Text>
        <Box w={24} />
      </Flex>
      {domains.filter((d) => enabled || d.domain === DEFAULT_DOMAIN).map((domain) => {
        const status = getDomainStatus(domain)

        return (
          <Flex px={6} py={3} justify="space-between" align="center" key={domain.id}>
            <Text
              w={1 / 3}
              fontSize="lg"
            >{domain.domain}
              {domain === sendingDomain() && <Badge ml={2}>Sending</Badge>}
            </Text>
            <Text
              color={status.color}
              fontWeight="semibold"
              w={1 / 3}
            ><Icon icon={status.icon} mr={1} /> {titleize(status.value)}
            </Text>
            <Menu
              buttonText="Options"
              menuItems={menuItems(domain)}
              noPadding
            />
          </Flex>
        )
      })}
    </RoundedList>
  )
}

type UnverifiedDomainsProps = {
  domains: CustomDomain[]
  onViewInstructions: (domain: CustomDomain) => void
}

const UnverifiedDomains = ({ domains, onViewInstructions }: UnverifiedDomainsProps) => (
  <>
    { domains.map((domain) => (
      <Stack key={domain.id} gap={6} borderRadius="xl" px={6} py={5} bgColor="blue.50" borderWidth={1} fontSize="lg">
        <Text>Custom email domain verification has been initiated for: <Text as="span" fontWeight="bold">{domain.domain}</Text>.</Text>
        <Text>Complete these 4 DNS modifications to verify your domain:
          <br />
          <Text
            as={Link}
            onClick={() => onViewInstructions(domain)}
          >View Verifications Instructions
          </Text>
        </Text>
        <Text>For further information, refer to the help article:<br />
          <Link isExternal href={DIOBOX_CUSTOM_EMAIL_DOMAIN_VERIFICATION_ARTICLE_LINK}>
            Custom Email Domains and Verification Steps
          </Link>
        </Text>
      </Stack>
    ))}
  </>
)

const DomainActionRequired = () => (
  <Box borderRadius="xl" px={6} py={3} bgColor="orange.50" borderWidth={1} fontSize="lg">
    <Box mb={4}>
      <Text as="span" fontWeight="bold">Action Required</Text>
    </Box>
    <Text>
      Google and Yahoo have announced new requirements for email senders.
      To ensure delivery of emails, we recommend you authenticate your domains.
      Select each domain marked as “Legacy Authenticated” and complete the new DNS changes.
    </Text>
  </Box>
)

const getDomainStatus = (domain: CustomDomain) => {
  if (domain.legacyAuthenticated) {
    return { value: DomainStatusValue.LegacyAuthenticated, color: "orange.400", icon: "checkmark" }
  } if (domain.validSigning) {
    return { value: DomainStatusValue.Authenticated, color: "green.400", icon: "checkmark" }
  } if (domain.verifiedAt) {
    return { value: DomainStatusValue.Pending, color: "teal.400", icon: "past-events" }
  }

  return { value: DomainStatusValue.NotAuthenticated, color: "red.400", icon: "times" }
}

export default CustomDomains
