import React from "react"
import { Box } from "@chakra-ui/react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { DioboxEvent, Form } from "sharedTypes"
import DraggableFormListItem from "./DraggableFormListItem"

type Props = {
  forms: Form[]
  onDragEnd: (result) => void
  event: DioboxEvent
  formId: string
  currency: string
  sortingEnabled: boolean
}

const EventFormListItems = ({
  forms,
  onDragEnd,
  event,
  formId,
  currency,
  sortingEnabled,
}: Props) => (
  <DragDropContext {...{ onDragEnd }}>
    <Droppable droppableId="forms-droppable">
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {forms.map((form, index) => (
            <DraggableFormListItem
              key={index}
              form={form}
              index={index}
              event={event}
              formId={formId}
              currency={currency}
              sortingEnabled={sortingEnabled}
            />
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  </DragDropContext>
)

export default EventFormListItems
