import React, { ReactNode } from "react"
import { ListItem, Link as ChakraLink, Box } from "@chakra-ui/react"
import { Link } from "react-router-dom"

interface EventResourcesListItemProps {
  children: ReactNode
  selected?: boolean
  path?: string | { pathname: any; state: { background: any; }; }
  showBottomBorder?: boolean
}

const EventResourceListItem = ({
  selected, path, children, showBottomBorder = true,
}: EventResourcesListItemProps) => {
  const selectedProps = {
    bg: "ui.selectedItemBg",
  }

  return (
    <ListItem borderBottomWidth={showBottomBorder ? 1 : 0}>
      {path ? (
        <ChakraLink
          {...selected && selectedProps}
          p={6}
          as={Link}
          to={path}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          _hover={{ textDecoration: "none" }}
        >
          {children}
        </ChakraLink>
      ) : (
        <Box p={6} display="flex" justifyContent="space-between">
          {children}
        </Box>
      )}

    </ListItem>
  )
}

export default EventResourceListItem
