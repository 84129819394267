import React from "react"
import NoContent from "components/elements/NoContent"
import useModal from "services/useModal"
import { ModalName } from "sharedTypes"
import AlertBoxWrapper from "components/elements/AlertBox/AlertBoxWrapper"
import { useIntercom } from "react-use-intercom"
import PendingTextingVerificationAlert from "./PendingTextingVerificationAlert"
import TextUpgradeAlert from "./TextUpgradeAlert"

type Props = {
  emailEnabled: boolean
  textsEnabled: boolean
}

const NoTexts = ({ emailEnabled, textsEnabled }: Props) => {
  const showModal = useModal()
  const { show: showIntercom } = useIntercom()

  let alertContent

  if (!emailEnabled) {
    alertContent = (
      <PendingTextingVerificationAlert contactUs={showIntercom} />
    )
  }

  if (!textsEnabled) {
    alertContent = (
      <TextUpgradeAlert />
    )
  }

  return (
    <>
      {alertContent && (
        <AlertBoxWrapper absolute>
          {alertContent}
        </AlertBoxWrapper>
      )}
      <NoContent
        title="Texting"
        icon="chat"
        descriptionItems={["Send bulk SMS messages to invite guests to register for your event or to provide them with confirmation links, directing them to their confirmation pages and QR codes."]}
        callsToActions={[{
          icon: "plus-big",
          text: "Create First Text",
          action: () => showModal(ModalName.NewTextMessage),
          isButton: true,
        }]}
      />
    </>
  )
}

export default NoTexts
