import React from "react"
import NoContent from "components/elements/NoContent"
import EventResourcesView from "components/layout/EventResourcesView"
import { Flex } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import SelectPrompt from "components/SelectPrompt"
import useModal from "services/useModal"
import {
  ModalName, EventCounts, DioboxEvent, Translations, Form,
} from "sharedTypes"
import Link from "components/elements/Link"
import { DIOBOX_FORMS_ARTICLE_LINK } from "utilities/externalLinks"
import SelectedForm from "../SelectedForm/SelectedForm"
import EventFormListItems from "./EventFormListItems"

type Props = {
  counts: EventCounts
  formId: string
  path: string
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  sortingEnabled: boolean
  forms: Form[]
  onDragEnd: (result) => void
}

const EventForms = ({
  event,
  counts,
  formId,
  path,
  updateEvent,
  defaultTranslations,
  sortingEnabled,
  forms,
  onDragEnd,
}: Props) => {
  const showModal = useModal()
  const { currency } = event
  const { forms: formsCount } = counts

  if (!formsCount) {
    return <NoForms onClick={() => showModal(ModalName.FormForm)} />
  }

  return (
    <EventResourcesView
      listItems={(
        <EventFormListItems
          forms={forms}
          onDragEnd={onDragEnd}
          event={event}
          formId={formId}
          currency={currency}
          sortingEnabled={sortingEnabled}
        />
)}
      showBorder={false}
      bgColor={formId ? "gray.50" : "white"}
      selectedItem={formId ? (
        <SelectedForm
          formId={formId}
          path={path}
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          formsCount={formsCount}
        />
      ) : <SelectPrompt text="Select a Form" />}
    />
  )
}

const NoForms = ({ onClick }) => {
  const descriptionItems = [
    "You can create forms or tickets that allow guests to register for your event. Diobox provides various form types that offer different functionalities.",
  ]

  const footer = (
    <Flex alignItems="center" mt={4}>
      <Link href={DIOBOX_FORMS_ARTICLE_LINK} target="_blank"><Icon icon="info" mr={2} />Learn more about Diobox Forms</Link>
    </Flex>
  )

  return (
    <NoContent
      title="Forms"
      icon="form-registration"
      descriptionItems={descriptionItems}
      callsToActions={[{
        icon: "plus-big", text: "Create First Form", action: onClick, isButton: true,
      }]}
      footer={footer}
    />
  )
}

export default EventForms
