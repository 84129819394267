import React from "react"
import {
  eventSubmissionsPath,
  eventEmailsPath, eventFormsPath, eventSeatingPath,
  eventSettingsPath, eventWebsitePath,
  eventTextingPath, useEventGuestPaths,
} from "utilities/routes"
import { DioboxEvent } from "sharedTypes"
import { Spacer } from "@chakra-ui/react"
import { useQueryClient } from "react-query"
import { guestsCacheKey } from "queries/guests"
import useGuestsStore from "services/useGuestsStore"
import usePermissions from "services/permissions"
import useFeatureFlag from "services/featureFlags"
import NavItem from "../NavItem"
import WebsiteStatusIndicator from "../NavItem/WebsiteStatusIndicator"

interface Props {
  event: DioboxEvent
  compact: boolean
}

const MenuItems = ({ event, compact }: Props) => {
  const queryClient = useQueryClient()
  const reset = useGuestsStore((s) => s.reset)
  const {
    canViewEmails,
    canViewForm,
    canViewGuests,
    canViewSeating,
    canViewSettings,
    canViewWebsite,
    canViewSubmissions,
    canViewTexting,
  } = usePermissions()
  const { texting: textingEnabled } = useFeatureFlag()
  const { eventGuestsPath } = useEventGuestPaths()

  const textingMenuEnabled = event.textsEnabled || textingEnabled

  const guestsMenuItem = (
    <NavItem
      type="event"
      icon="guests"
      title="Guests"
      path={eventGuestsPath(event.id, "")}
      onClick={() => {
        queryClient.invalidateQueries(guestsCacheKey(event.id))
        reset()
      }}
      compact={compact}
    />
  )
  const emailsMenuItem = <NavItem type="event" icon="emails" title="Emails" path={eventEmailsPath(event.id)} compact={compact} />
  const seatingMenuItem = (
    <NavItem
      type="event"
      icon="seating"
      title="Seating"
      path={eventSeatingPath(event.id)}
      onClick={() => queryClient.invalidateQueries(guestsCacheKey(event.id))}
      compact={compact}
    />
  )
  const spacerMenuItem = <Spacer h={8} />
  const formsMenuItem = <NavItem type="event" icon="forms" title="Forms" path={eventFormsPath(event.id)} compact={compact} />
  const submissionsMenuItem = <NavItem type="event" icon="data-collection" title="Submissions" path={eventSubmissionsPath(event.id)} compact={compact} />
  const webPageMenuItem = <NavItem type="event" icon="website" title="Web Page" path={eventWebsitePath(event.id)} indicator={<WebsiteStatusIndicator website={event.website} />} compact={compact} />
  const eventSettingsMenuItem = <NavItem type="event" icon="settings" title="Event Settings" path={eventSettingsPath(event.id)} compact={compact} />
  const textingMenuItem = <NavItem type="event" icon="texts-nav" title="Texts" path={eventTextingPath(event.id)} compact={compact} />

  return (
    <>
      {canViewGuests && guestsMenuItem}
      {canViewEmails && emailsMenuItem}
      {textingMenuEnabled && canViewTexting && textingMenuItem}
      {canViewForm && formsMenuItem}
      {canViewSubmissions && submissionsMenuItem}
      {canViewWebsite && webPageMenuItem}
      {canViewSeating && seatingMenuItem}
      {spacerMenuItem}
      {canViewSettings && eventSettingsMenuItem}
    </>
  )
}

export default MenuItems
