import React from "react"
import { Box, VStack } from "@chakra-ui/react"
import { DioboxEvent, Translations } from "sharedTypes"
import EventBranding from "./EventBranding"
import PageBranding from "./PageBranding"
import PageWithPreview from "../PageWithPreview"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  eventPreview: DioboxEvent
  onChangeEventPreview: (values: Partial<DioboxEvent>) => void
}

const Header = ({
  event,
  updateEvent,
  defaultTranslations,
  eventPreview,
  onChangeEventPreview,
}: Props) => (
  <PageWithPreview eventPreview={eventPreview}>
    <VStack align="flex-start" spacing={16}>
      <Box>
        <EventBranding
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          onChangeEventPreview={onChangeEventPreview}
        />
      </Box>
      <Box>
        <PageBranding
          event={event}
          updateEvent={updateEvent}
          onChangeEventPreview={onChangeEventPreview}
        />
      </Box>
    </VStack>
  </PageWithPreview>
)

export default Header
