import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { asDate } from "utilities/strings"
import { DateFormat } from "utilities/constants"
import Icon from "./elements/Icon"

type Props = {
  onClick: () => void
  title: string
  icon: string
  sentOn: string|null
  disableDraftItems?: boolean
}

const EmailOrTextMessageListItem = ({
  onClick, title, icon, sentOn, disableDraftItems,
}: Props) => {
  const isDisabled = disableDraftItems && !sentOn
  const wrapperProps = isDisabled ? { color: "gray.500" } : { cursor: "pointer", onClick }

  return (
    <Flex py={3} px={4} gap={4} align="center" borderBottomWidth={1} {...wrapperProps}>
      <Icon icon={icon} size={8} />
      <Box>
        <Text fontSize="lg">{title}</Text>
        {sentOn && <Text fontSize="sm">{asDate(sentOn, DateFormat.MONTH_DAY_YEAR_AT_HOUR_MINUTE)}</Text>}
        {isDisabled && <Text fontSize="sm">Draft</Text>}
      </Box>
      <Icon icon="next-arrow" size={5} my={3} ml="auto" />
    </Flex>
  )
}

export default EmailOrTextMessageListItem
