import React, { useEffect, useState } from "react"
import { Box, Flex, Stack } from "@chakra-ui/react"
import EventPage, { EventPageHeader } from "components/layout/EventPage"
import HorizontalStat from "components/elements/HorizontalStat"
import Button from "components/Buttons/Button"
import useModal from "services/useModal"
import pluralize from "pluralize"
import {
  AccountConfiguration,
  AccountUser,
  DioboxEvent,
  EventCounts,
  Form,
  FormsSubpath,
  ModalName,
  TabButton,
} from "sharedTypes"
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"

import { errorToast, successToast } from "utilities/toasts"
import { eventFormsPath } from "utilities/routes"
import { MAX_FORMS_PER_EVENT } from "utilities/constants"
import HeaderTabs from "components/elements/HeaderTabs"
import NavigationTabButton from "components/Navigation/HeaderTabs/NavigationTabButton"
import { useForms } from "queries/forms"
import { moveElement, sortByOrderList } from "utilities/objects"
import { postFormsOrder } from "api/forms"
import { refetchForms } from "context/actions"
import { useQueryClient } from "react-query"
import Spinner from "components/elements/Spinner"
import Terminology from "./Terminology/Terminology"
import EmailResponders from "./EmailResponders/EmailResponders"
import GuestPortal from "./GuestPortal/GuestPortal"
import Header from "./Header/Header"
import SharePopover from "./SharePopover"
import EventForms from "./Forms/EventForms/EventForms"
import Colors from "./Colors/Colors"
import EditOrder from "./EditOrder"

interface Props {
  event: DioboxEvent
  counts: EventCounts
  updateEvent: (params: {}) => Promise<void>
  accountConfiguration: AccountConfiguration
  accountUsers: AccountUser[]
}

const Forms = ({
  event: { id: eventId },
  event,
  counts,
  updateEvent,
  accountConfiguration,
  accountUsers,
}: Props) => {
  const showModal = useModal()
  const location = useLocation()
  const { path } = useRouteMatch()
  const { subpath } = useParams<{ subpath?: string }>()
  const { formId } = useParams<{ formId: string}>()
  const { translations: defaultTranslations } = accountConfiguration
  const { forms: formsCount } = counts
  const [eventPreview, setEventPreview] = useState(event)
  const [sortingEnabled, setSorting] = useState(false)
  const { isLoading, data } = useForms(eventId)
  const queryClient = useQueryClient()

  const forms = data?.data
  const initialOrder = forms?.map((form) => form.id) ?? []
  const [order, setOrder] = useState(initialOrder)

  useEffect(() => {
    setOrder(initialOrder)
  }, [forms])

  useEffect(() => {
    setEventPreview(event)
  }, [event.portalEventImageUrl, event.portalLogoUrl])

  if (isLoading) {
    return <Spinner />
  }

  if (!forms) {
    return null
  }

  const onDragEnd = (result) => {
    const { source, destination } = result
    setOrder(moveElement(order, source.index, destination.index))
  }

  const sortedForms = sortByOrderList<Form>(forms, order)

  const handleCancelSorting = () => {
    setSorting(false)
    setOrder(initialOrder)
  }

  const handleSaveSorting = async () => {
    setSorting(false)
    try {
      await postFormsOrder(eventId, order)
    } catch (error) {
      setOrder(initialOrder)
    }

    await refetchForms(queryClient, eventId)
    successToast({ title: "Form order saved." })
  }

  const handleClick = () => {
    if (formsCount >= MAX_FORMS_PER_EVENT) {
      errorToast({
        title: `You can create up to ${MAX_FORMS_PER_EVENT} Forms per event`,
      })

      return
    }

    showModal(ModalName.FormForm)
  }

  const FORMS_PAGE_TABS = [{
    id: 0,
    title: "Forms",
    iconName: "ticketing-orders",
  },
  {
    id: 1,
    title: "Guest Portal",
    subpath: FormsSubpath.GuestPortal,
    iconName: "home",
  },
  {
    id: 2,
    title: "Header",
    subpath: FormsSubpath.Header,
    iconName: "site-homepage",
  },
  {
    id: 3,
    title: "Colors",
    subpath: FormsSubpath.Colors,
    iconName: "branding-outline",
  },
  {
    id: 4,
    title: "Terminology",
    subpath: FormsSubpath.Terminology,
    iconName: "terminology-outline",
  },
  {
    id: 5,
    title: "Email Responders",
    subpath: FormsSubpath.EmailResponders,
    iconName: "email-responders-outline",
  }]

  // If there's no active tab, default to the first tab
  const activeTab = FORMS_PAGE_TABS.find((tab) => tab.subpath === subpath) ?? FORMS_PAGE_TABS[0]

  const handleChangeEventPreview = (values: Partial<DioboxEvent>) => {
    setEventPreview((oldEventPreview) => ({ ...oldEventPreview, ...values }))
  }

  const eventPageHeaderItems = (
    <Stack direction="row" spacing={4} align="center" height="50px">
      {!sortingEnabled && <HorizontalStat label={pluralize("Form", formsCount)} value={formsCount} />}
      <Flex>
        {!subpath || subpath === FormsSubpath.Forms ? (
          <EditOrder
            sortingEnabled={sortingEnabled}
            onEnableSorting={() => { setSorting(true) }}
            onCancelSorting={handleCancelSorting}
            onSaveSorting={handleSaveSorting}
          />
        ) : (
          <SharePopover event={event} />
        )}
        {!sortingEnabled && (
          <Box ml={4}>
            <Button leftIcon="plus" onClick={handleClick} size="sm">
              Form
            </Button>
          </Box>
        )}
      </Flex>
    </Stack>
  )

  return (
    <EventPage
      header={(
        <EventPageHeader
          icon="forms"
          title="Forms"
          subtitle={
            <HeaderNav eventId={eventId} tabs={FORMS_PAGE_TABS} activeTab={activeTab} />
          }
          items={eventPageHeaderItems}
        />
      )}
      body={(
        <Switch location={location}>
          <Route
            path={`${eventFormsPath(eventId, FormsSubpath.GuestPortal)}`}
          >
            <GuestPortal
              event={event}
              updateEvent={updateEvent}
              eventPreview={eventPreview}
            />
          </Route>
          <Route
            path={`${eventFormsPath(eventId, FormsSubpath.Header)}`}
          >
            <Header
              event={event}
              onChangeEventPreview={handleChangeEventPreview}
              updateEvent={updateEvent}
              eventPreview={eventPreview}
              defaultTranslations={defaultTranslations}
            />
          </Route>
          <Route
            path={`${eventFormsPath(eventId, FormsSubpath.Colors)}`}
          >
            <Colors
              event={event}
              onChangeEventPreview={handleChangeEventPreview}
              updateEvent={updateEvent}
              eventPreview={eventPreview}
            />
          </Route>
          <Route
            path={`${eventFormsPath(eventId, FormsSubpath.Terminology)}`}
          >
            <Terminology
              event={event}
              updateEvent={updateEvent}
              defaultTranslations={defaultTranslations}
            />
          </Route>
          <Route
            path={`${eventFormsPath(eventId, FormsSubpath.EmailResponders)}`}
          >
            <EmailResponders
              accountUsers={accountUsers}
              updateEvent={updateEvent}
            />
          </Route>
          <Route path={`${eventFormsPath(eventId)}/:formId?`}>
            <EventForms
              event={event}
              counts={counts}
              formId={formId}
              path={path}
              updateEvent={updateEvent}
              defaultTranslations={defaultTranslations}
              sortingEnabled={sortingEnabled}
              forms={sortedForms}
              onDragEnd={onDragEnd}
            />
          </Route>
        </Switch>
      )}
    />
  )
}

type HeaderNavProps = {
  eventId: string
  tabs: TabButton<FormsSubpath>[]
  activeTab?: TabButton<FormsSubpath>
}

const HeaderNav = ({ eventId, tabs, activeTab }: HeaderNavProps) => (
  <HeaderTabs>
    {tabs.map(({
      id, title, subpath, iconName,
    }) => (
      <NavigationTabButton
        key={subpath}
        title={title}
        iconName={iconName}
        active={activeTab?.id === id}
        path={eventFormsPath(eventId, subpath)}
      />
    ))}
  </HeaderTabs>
)

export default Forms
