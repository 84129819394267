import { ListItem } from "@chakra-ui/react"
import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { DioboxEvent, Form } from "sharedTypes"
import EventFormListItem from "./EventFormListItem"

type Props = {
  form: Form
  index: number
  formId: string
  event: DioboxEvent
  currency: string
  sortingEnabled: boolean
}

const DraggableFormListItem = ({
  form, index, formId, event, currency, sortingEnabled,
}: Props) => (
  <Draggable
    key={form.externalId}
    draggableId={form.externalId.toString()}
    index={index}
    isDragDisabled={!sortingEnabled}
  >
    {(provided, snapshot) => (
      <ListItem
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        selected={snapshot.isDragging}
      >
        <EventFormListItem
          key={form.externalId}
          form={form}
          selected={form.externalId === formId}
          event={event}
          currency={currency}
          sortingEnabled={sortingEnabled}
          showBottomBorder={!snapshot.isDragging}
        />
      </ListItem>
    )}
  </Draggable>
)

export default DraggableFormListItem
