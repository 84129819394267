import { Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import EventResourceListItem from "components/layout/EventResourceListItem"
import React from "react"
import { DioboxEvent, Form, FormType } from "sharedTypes"
import { formIconAndType, formStatusAndColor } from "utilities/forms"
import { eventFormPath } from "utilities/routes"
import { asMoney } from "utilities/strings"

type ListItemProps = {
  form: Form
  selected: boolean
  event: DioboxEvent
  currency: string
  sortingEnabled: boolean
  showBottomBorder: boolean
}

const EventFormListItem = ({
  form, selected, event, currency, sortingEnabled, showBottomBorder,
}: ListItemProps) => {
  const { color } = formStatusAndColor(form, event)
  const {
    type,
    variablePrice,
    price,
    externalId,
    private: isPrivate,
  } = form
  const { icon } = formIconAndType(type)

  const generateSubtitle = () => {
    if (type !== FormType.Ticket) {
      return null
    }
    if (variablePrice) {
      return "Variable Price"
    }

    return asMoney(price, currency)
  }

  const subtitle = generateSubtitle()

  return (
    <EventResourceListItem
      selected={selected}
      path={!sortingEnabled ? eventFormPath(event.id, externalId) : undefined}
      key={externalId}
      showBottomBorder={showBottomBorder}
    >
      <>
        <Flex align="center" mr={5}>
          <Icon icon={icon} size={8} mr={5} />
          <Flex minH={12} flexDirection="column" justifyContent="center">
            <Text fontSize="xl" noOfLines={1}>{form.name}</Text>
            {subtitle && <Text fontSize="sm">{subtitle}</Text>}
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {isPrivate && <Icon icon="closed-eye" size={5} mr={3} color="gray.500" />}
          <Icon color={color} size={3.5} icon="dot" />
          {sortingEnabled && (
            <Flex>
              <Icon icon="menu" color="gray.400" size={6} ml={3} alignSelf="flex-start" />
            </Flex>
          )}
        </Flex>
      </>
    </EventResourceListItem>
  )
}

export default EventFormListItem
