import React, { useState } from "react"
import {
  Box, Flex, Link, Text, VStack,
} from "@chakra-ui/react"
import { annualDiscount, asMoney, titleize } from "utilities/strings"
import { postSubscription } from "api/accounts"
import Summary from "pages/Account/Plan/Summary"
import Button from "components/Buttons/Button"
import Icon from "components/elements/Icon"
import { updateCurrentAccount } from "context/actions"
import { useQueryClient } from "react-query"
import { Plan } from "sharedTypes"
import { usePreviewInvoice } from "queries/plans"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"

interface Props {
  currentPlan: Plan
  toInterval: "month" | "year"
}

const ChangeBillingInterval = ({ currentPlan, toInterval }: Props) => {
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState(false)

  const {
    isFetching,
    previewInvoice: {
      amountDue,
      unusedTimeAmount,
      startingBalance,
      discountAmount,
      prorationDate,
    },
  } = usePreviewInvoice(currentPlan.stripePriceIds[toInterval], showModal)

  const onSubmit = () => postSubscription(
    {
      subscription: {
        type: currentPlan.type,
        stripePriceId: currentPlan.stripePriceIds[toInterval],
        prorationDate,
      },
    },
  ).then((response) => {
    updateCurrentAccount(queryClient, response.data)
    setShowModal(false)
  }).catch(() => {
    // Error message is displayed to the user automatically,
    // we just catch the error without closing the modal
  })

  const trigger = () => {
    if (toInterval === "month") {
      return (
        <>
          <Icon icon="dot" size={1} ml={2} />
          <Link
            fontSize="xs"
            ml={2}
            onClick={() => setShowModal(true)}
          >
            Switch to Monthly Billing
          </Link>
        </>
      )
    }

    return (
      <Box bgColor="gray.50" borderWidth={1} p={5} mt={8}>
        <VStack spacing={4}>
          <Text>
            <Text as="span" color="orange.400" fontWeight="semibold">Save {asMoney(annualDiscount("year", currentPlan), "USD", true)}</Text> by switching to annual billing and receiving a 40% discount.
          </Text>
          <Button variant="outline" onClick={() => setShowModal(true)}>Switch to Annual Billing</Button>
        </VStack>
      </Box>
    )
  }

  return (
    <>
      {trigger()}
      {showModal && (
      <ModalForm
        title={`Switch to ${titleize(toInterval)}ly Billing`}
        submitText="Change Plan"
        onClose={() => {
          setShowModal(false)
        }}
        onSubmit={onSubmit}
      >
        <Text fontSize="xl" mb={3}>Are you sure you want to switch to the {toInterval}ly plan?</Text>
        <Flex
          py={2}
          px={3}
          borderWidth={2}
          borderColor="blue.400"
          bgColor="blue.50"
          align="center"
          justify="space-between"
          fontSize="lg"
          mb={8}
        >
          <Box>
            <Text>Your New Subscription Fee</Text>
            <Text>({toInterval === "year" ? "Annual" : "Monthly"} Billing)</Text>
          </Box>
          <Text><Text fontSize="xl" as="span">{asMoney(toInterval === "year" ? currentPlan.annualPrice / 12 : currentPlan.monthlyPrice)}</Text>/mo</Text>
        </Flex>
        <Summary
          loading={isFetching}
          planType={currentPlan.type}
          interval={toInterval}
          upgradeAmount={toInterval === "year" ? currentPlan.annualPrice : currentPlan.monthlyPrice}
          creditAmount={(
            (startingBalance || 0) + (unusedTimeAmount || 0) - (discountAmount || 0)) / -100}
          amountDue={(amountDue || 0) / 100}
        />
      </ModalForm>
      )}
    </>
  )
}

export default ChangeBillingInterval
