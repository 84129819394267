import React from "react"
import DocsLink from "components/DocsLink"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { SubscriptionType } from "sharedTypes"
import { TEXT_MESSAGING_ARTICLE_LINK } from "utilities/externalLinks"

const TextUpgradeAlert = () => (
  <UpgradeAlert
    title="Send SMS Messages with an Enterprise Subscription"
    text={(
      <>
        With an average open rate of 98%, texting is a highly effective communication
        method. Diobox enables you to send individual or mass text messages to your guests
        and share RSVP or registration forms, confirmation links, and other updates.
        <DocsLink url={TEXT_MESSAGING_ARTICLE_LINK} display="block" mt={2}>
          Learn more about Diobox Texts
        </DocsLink>
      </>
    )}
    defaultSelectedPlan={SubscriptionType.Enterprise}
  />
)

export default TextUpgradeAlert
