import React from "react"
import { useHistory } from "react-router-dom"
import {
  Center, Heading, Stack, Text,
} from "@chakra-ui/react"
import Modal from "components/dialogs/Modal"
import Icon from "components/elements/Icon"
import ConfettiExplosion from "react-confetti-explosion"
import Button from "../components/Buttons/Button"
import { eventsPath } from "../utilities/routes"

type Props = {
  onHide: () => void
}

export const UpgradeSuccessful = ({ onHide }: Props) => {
  const history = useHistory()

  const handleClickBack = () => {
    history.push(eventsPath())
    onHide()
  }

  return (
    <Center>
      <ConfettiExplosion width={700} particleCount={300} zIndex={1800} />
      <Modal
        show
        hide={onHide}
        size="lg"
        closeOnEsc={false}
      >
        <Stack my={10} alignItems="center" textAlign="center" spacing={5}>
          <Icon size={14} icon="checkin-filled" color="blue.500" />
          <Heading mb={2} size="lg" fontWeight="semibold">Upgrade Successful</Heading>
          <Text fontSize="lg">Thank you! Your plan has been upgraded successfully.</Text>
          <Text fontSize="lg">You can find your receipt in <b>Account &gt; Plan & Billing</b>.</Text>
          <Button onClick={handleClickBack}>Back to Events</Button>
        </Stack>
      </Modal>
    </Center>
  )
}

export default UpgradeSuccessful
