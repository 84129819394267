import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { DioboxEvent, Guest } from "sharedTypes"
import * as api from "api/guests"
import { refetchGuests } from "context/actions"
import { warningToast } from "utilities/toasts"
import Menu, { MenuGroup } from "components/dialogs/Menu"
import { Box, useDisclosure } from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import { useQueryClient } from "react-query"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { getGuestPhoneNumber } from "utilities/objects"
import { useEventGuestPaths } from "utilities/routes"
import validatePhoneNumber from "utilities/validatePhoneNumber"
import GuestMenuItem from "./Item"
import Subscribe from "../Subscribe"

interface Props {
  guest: Guest
  event: DioboxEvent
}

const GuestMenu = ({ guest, event }: Props) => {
  const { id: eventId } = event
  const queryClient = useQueryClient()
  const history = useHistory()
  const location = useLocation()
  const disclosure = useDisclosure()
  const { onClose } = disclosure
  const { eventGuestsPath, eventGuestPath } = useEventGuestPaths()

  React.useEffect(() => {
    onClose()
  }, [location])

  const deleteGuest = (deletedGuest: Guest) => {
    api.deleteGuest(eventId, deletedGuest.id).then(() => {
      refetchGuests(queryClient, eventId)
      history.push(eventGuestsPath(eventId))
    })
  }

  if (!guest.id) {
    return null
  }

  const {
    personInfo: {
      email, firstName, lastName, unsubscribed,
    },
    id: guestId,
  } = guest

  const sendWarningText = () => {
    if (!email) {
      return "Guest does not have an email address"
    }
    if (unsubscribed) {
      return "Guest is unsubscribed"
    }

    return null
  }

  const handleSendTextMessage = () => {
    const phoneNumberField = getGuestPhoneNumber(guest)

    if (!phoneNumberField) {
      warningToast({ title: "The guest has no phone number" })

      return
    }

    const phoneNumber = `${phoneNumberField.value}`
    const phoneNumberIsValid = validatePhoneNumber(phoneNumber)

    if (!phoneNumberIsValid) {
      warningToast({ title: `Guest phone number is invalid: ${phoneNumber}` })

      return
    }

    history.push(eventGuestPath(eventId, guestId, "send-text-message"))
  }

  return (
    <Menu
      {...disclosure}
      button={
        <Button as={Box} leftIcon="guest-2" rightIcon="down-arrow" size="sm" variant="outline">Options</Button>
      }
      noPadding
      menuItems={[
        <MenuGroup title="Guest Data" first>
          <GuestMenuItem icon="edit-guest2" title="Edit Guest" link={eventGuestPath(eventId, guestId, "edit")} />
          <GuestMenuItem icon="track-activity" title="Add Note" link={eventGuestPath(eventId, guestId, "activities/add/note")} />
          <GuestMenuItem icon="enhance" title="Enrich Guest" link={eventGuestPath(eventId, guestId, "enrich")} />
          <GuestMenuItem icon="history" title="View History" link={eventGuestPath(eventId, guestId, "history-and-scoreboard")} />
        </MenuGroup>,
        <MenuGroup title="Guest Engagement">
          <GuestMenuItem
            icon="email-only-large"
            title="Send Email"
            onClick={() => (sendWarningText() ? warningToast({ title: sendWarningText() }) : history.push(eventGuestPath(eventId, guestId, "send-email")))}
          />
          <GuestMenuItem
            icon="smart-reminder"
            title="Send Smart Reminder"
            onClick={() => (sendWarningText() ? warningToast({ title: sendWarningText() }) : history.push(eventGuestPath(eventId, guestId, "send-smart-reminder")))}
          />
          <GuestMenuItem
            icon="texts"
            title="Send Text Message"
            onClick={handleSendTextMessage}
          />
          <Subscribe guest={guest}>
            <GuestMenuItem
              icon={`${unsubscribed ? "email-only-large" : "unsubscribe-envelope"}`}
              title={`${unsubscribed ? "Resubscribe" : "Unsubscribe"} Guest`}
            />
          </Subscribe>
          <GuestMenuItem icon="checkin-alert" title="Set Check-In Alert" link={eventGuestPath(eventId, guestId, "check-in-alert")} />
          <GuestMenuItem icon="external-link" title="View Guest Portal" href={`${import.meta.env.VITE_WEBSITE_HOST}/g/${guest.externalId}`} />
          <Box borderTopWidth={1} mt={2}>
            <ConfirmDelete
              type="Guest"
              name={`${firstName} ${lastName}`}
              from="this event"
            >
              <GuestMenuItem
                color="red.500"
                py={3}
                icon="times"
                title="Remove from Event"
                onClick={() => deleteGuest(guest)}
              />
            </ConfirmDelete>
          </Box>
        </MenuGroup>,
      ]}
    />
  )
}

export default GuestMenu
